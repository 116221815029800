import Vue from 'vue'
import Page from '~/components/Page.vue'
import HeroSectionHomepage from '~/components/HeroSectionHomepage.vue'
import HeroHomeAnim from '~/components/HeroHomeAnim.vue'
import HeroSectionDefault from '~/components/HeroSectionDefault.vue'
import HeaderColor from '~/components/HeaderColor.vue'
import LinkButton from '~/components/LinkButton.vue'
import MailtoButton from '~/components/MailtoButton.vue'
import TextSlider from '~/components/TextSlider.vue'
import LogosSection from '~/components/LogosSection.vue'
import TwoBoxesSection from '~/components/TwoBoxesSection.vue'
import FeaturesSection from '~/components/FeaturesSection.vue'
import FeaturesNeuSection from '~/components/FeaturesNeuSection.vue'
import FeaturesRowsSection from '~/components/FeaturesRowsSection.vue'
import BreakBlockSection from '~/components/BreakBlockSection.vue'
import BoxesBreakBlock from '~/components/BoxesBreakBlock.vue'
import ImageSection from '~/components/ImageSection.vue'
import AccordionSection from '~/components/AccordionSection.vue'
import ClosedAccordionSection from '~/components/ClosedAccordionSection.vue'
import ContactSection from '~/components/ContactSection.vue'
import QuotesSection from '~/components/QuotesSection.vue'
import TabletSection from '~/components/TabletSection.vue'
import LaptopSection from '~/components/LaptopSection.vue'
import PacketsSection from '~/components/PacketsSection.vue'
import ReferencesSection from '~/components/ReferencesSection.vue'
import CompanySection from '~/components/CompanySection.vue'
import ArchiveSection from '~/components/ArchiveSection.vue'
import PresseSection from '~/components/PresseSection.vue'
import CaseStudiesSection from '~/components/CaseStudiesSection.vue'
import CareerSection from '~/components/CareerSection.vue'
import TeamSection from '~/components/TeamSection.vue'
import SeparatorSection from '~/components/SeparatorSection.vue'
import SeparatorWhite from '~/components/SeparatorWhite.vue'
import NewsletterSection from '~/components/NewsletterSection.vue'
import ContactEmailSection from '~/components/ContactEmail.vue'
import ImagePlayButtons from '~/components/ImagePlayButtons.vue'
import ImageRed from '~/components/ImageRed.vue'
import ImageGreen from '~/components/ImageGreen.vue'
import PdfSection from '~/components/PdfSection.vue'
import ImageSeparator from '~/components/ImageSeparator.vue'
import NewsSlider from '~/components/NewsSlider.vue'
import PostBody from '~/components/PostBody.vue'
import PostImage from '~/components/PostImage.vue'
import PostLink from '~/components/PostLink.vue'
import TheHeader from '~/components/TheHeader.vue'
import TheFooter from '~/components/TheFooter.vue'
import TitleSection from '~/components/TitleSection.vue'
import HeroSectionVideo from '~/components/HeroSectionVideo.vue'

import ArrowBackground from '~/components/english-site/ArrowBackground.vue'
import GradientLink from '~/components/english-site/GradientLink.vue'
import Marquee from '~/components/english-site/Marquee.vue'
import Media from '~/components/english-site/Media.vue'
import Navbar from '~/components/english-site/Navbar.vue'
import PageFooter from '~/components/english-site/PageFooter.vue'
import PlayArrow from '~/components/english-site/PlayArrow.vue'
import RequestForm from '~/components/english-site/RequestForm.vue'
import SectionBasicFeatures from '~/components/english-site/SectionBasicFeatures.vue'
import SectionContact from '~/components/english-site/SectionContact.vue'
import SectionFaq from '~/components/english-site/SectionFaq.vue'
import SectionFeatures from '~/components/english-site/SectionFeatures.vue'
import SectionHero from '~/components/english-site/SectionHero.vue'
import SectionReferences from '~/components/english-site/SectionReferences.vue'
import SectionServices from '~/components/english-site/SectionServices.vue'
import SectionTimeline from '~/components/english-site/SectionTimeline.vue'
import SectionUseCases from '~/components/english-site/SectionUseCases.vue'
import TimelineRoot from '~/components/english-site/TimelineRoot.vue'
import TimelineStep from '~/components/english-site/TimelineStep.vue'
import VariableCarousel from '~/components/english-site/VariableCarousel.vue'

Vue.component('page', Page)
Vue.component('hero_section_homepage', HeroSectionHomepage)
Vue.component('hero_home_anim', HeroHomeAnim)
Vue.component('hero_section_default', HeroSectionDefault)
Vue.component('header_color', HeaderColor)
Vue.component('block.link_button', LinkButton)
Vue.component('block.mailto', MailtoButton)
Vue.component('hero_section.text_slider', TextSlider)
Vue.component('logos_section', LogosSection)
Vue.component('two_boxes_section', TwoBoxesSection)
Vue.component('features_section', FeaturesSection)
Vue.component('features_neu_section', FeaturesNeuSection)
Vue.component('features_rows_section', FeaturesRowsSection)
Vue.component('break_block_section', BreakBlockSection)
Vue.component('boxes_break_block', BoxesBreakBlock)
Vue.component('image_section', ImageSection)
Vue.component('accordion_section', AccordionSection)
Vue.component('closed_accordion_section', ClosedAccordionSection)
Vue.component('contact_section', ContactSection)
Vue.component('quotes_section', QuotesSection)
Vue.component('tablet_section', TabletSection)
Vue.component('laptop_section', LaptopSection)
Vue.component('packets_section', PacketsSection)
Vue.component('references_section', ReferencesSection)
Vue.component('company_section', CompanySection)
Vue.component('archive_section', ArchiveSection)
Vue.component('presse_section', PresseSection)
Vue.component('case_studies_section', CaseStudiesSection)
Vue.component('career_section', CareerSection)
Vue.component('team_section', TeamSection)
Vue.component('separator_section', SeparatorSection)
Vue.component('separator_white', SeparatorWhite)
Vue.component('newsletter_section', NewsletterSection)
Vue.component('contact_email_section', ContactEmailSection)
Vue.component('image_play_buttons', ImagePlayButtons)
Vue.component('image_red', ImageRed)
Vue.component('image_green', ImageGreen)
Vue.component('pdf_section', PdfSection)
Vue.component('image_separator', ImageSeparator)
Vue.component('news_slider', NewsSlider)
Vue.component('post_body', PostBody)
Vue.component('post_image', PostImage)
Vue.component('post_link', PostLink)
Vue.component('header-menu', TheHeader)
Vue.component('footer-menu', TheFooter)
Vue.component('title_section', TitleSection)
Vue.component('header_video', HeroSectionVideo)

// English site components registration
Vue.component('arrow_background', ArrowBackground)
Vue.component('gradient_link', GradientLink)
Vue.component('marquee', Marquee)
Vue.component('media', Media)
Vue.component('navbar', Navbar)
Vue.component('page_footer', PageFooter)
Vue.component('play_arrow', PlayArrow)
Vue.component('request_form', RequestForm)
Vue.component('section_basic_features', SectionBasicFeatures)
Vue.component('section_contact', SectionContact)
Vue.component('section_faq', SectionFaq)
Vue.component('section_features', SectionFeatures)
Vue.component('section_hero', SectionHero)
Vue.component('section_references', SectionReferences)
Vue.component('section_services', SectionServices)
Vue.component('section_timeline', SectionTimeline)
Vue.component('section_use_cases', SectionUseCases)
Vue.component('timeline_root', TimelineRoot)
Vue.component('timeline_step', TimelineStep)
Vue.component('variable_carousel', VariableCarousel)

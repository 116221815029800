//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    kpi: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: "",
    },
    icon: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      entered: false,
    };
  },
  mounted() {
    this.$ScrollTrigger.create({
      trigger: this.$el,
      toggleActions: "play none none none",
      start: "+=133 90%",
      onEnter: () => {
        this.entered = true;
      },
    });
  },
};

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.block.hidden)?_c('div',{ref:"closedAccordion",staticClass:"section closed-accordion"},[_c('div',{staticClass:"container"},[(_vm.block.headline)?_c('h2',{staticClass:"closed-accordion-title text-gray mb-5 text-dark",class:'with-text-gradient gradient-' + _vm.block.headline_color,domProps:{"innerHTML":_vm._s(_vm.block.headline)}}):_vm._e(),_vm._v(" "),_vm._l((_vm.paginate),function(item){return _c('div',{key:item.id,staticClass:"closed-accordion-item d-flex align-items-center py-4"},[_c('div',{staticClass:"closed-accordion-item--wrapper"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-lg-8 title d-flex align-items-center mb-4 mb-lg-0"},[_c('font-awesome-icon',{staticClass:"fa-icon caret-right mr-3",attrs:{"icon":{ prefix: 'fa', iconName: 'caret-right' }}}),_vm._v(" "),(item.headline)?_c('h3',{class:_vm.sitePresse ? 'font-light mb-0' : 'h2 mb-0 text-gray'},[_vm._v("\n              "+_vm._s(item.headline)+"\n            ")]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"col-12 offset-lg-1 col-lg-3"},[(item.intern_link && item.intern_link.page.data)?_c('link-button',{class:_vm.sitePresse
                  ? 'closed-accordion-link-presse'
                  : 'closed-accordion-link',attrs:{"link":item.intern_link.page.data.attributes.slug,"label":item.intern_link.label && item.intern_link.label}}):(item.intern_link && item.intern_link.blog_post.data)?_c('link-button',{class:_vm.sitePresse
                  ? 'closed-accordion-link-presse'
                  : 'closed-accordion-link',attrs:{"link":item.intern_link.blog_post.data.attributes.slug,"label":item.intern_link.label && item.intern_link.label}}):(item.intern_link && item.intern_link.case_study.data)?_c('link-button',{class:_vm.sitePresse
                  ? 'closed-accordion-link-presse'
                  : 'closed-accordion-link',attrs:{"link":item.intern_link.case_study.data.attributes.slug,"label":item.intern_link.label && item.intern_link.label}}):(
                item.intern_link && item.intern_link.presse_post.data
              )?_c('link-button',{class:_vm.sitePresse
                  ? 'closed-accordion-link-presse'
                  : 'closed-accordion-link',attrs:{"link":item.intern_link.presse_post.data.attributes.slug,"label":item.intern_link.label && item.intern_link.label}}):(item.extern_link)?_c('extern-link',{class:_vm.sitePresse
                  ? 'closed-accordion-link-presse'
                  : 'closed-accordion-link',attrs:{"link":item.extern_link.url && item.extern_link.url,"label":item.extern_link.label && item.extern_link.label}}):_vm._e()],1)])])])}),_vm._v(" "),(_vm.totalPages > 1)?_c('ul',{staticClass:"pagination mt-4 mt-lg-5"},_vm._l((_vm.totalPages),function(pageNumber,index){return _c('li',{key:index},[(
            Math.abs(pageNumber - _vm.currentPage) < 3 ||
            pageNumber == _vm.totalPages ||
            pageNumber == 1
          )?_c('a',{key:pageNumber,class:{
            current: _vm.currentPage === pageNumber,
            last:
              pageNumber == _vm.totalPages &&
              Math.abs(pageNumber - _vm.currentPage) > 3,
            first: pageNumber == 1 && Math.abs(pageNumber - _vm.currentPage) > 3,
          },on:{"click":function($event){return _vm.setPage(pageNumber)}}},[_vm._v(_vm._s(pageNumber))]):_vm._e()])}),0):_vm._e()],2)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
//
//
//
//
//
//
//
//
//
//

import BreakBlockBoxesSection from "./BreakBlockBoxesSection.vue";
export default {
  name: "BoxesBreakBlock",
  components: {
    BreakBlockBoxesSection,
  },
  props: {
    block: {
      type: Object,
    },
    breakBlockData: {
      type: Object,
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ReadMoreButton from "./ReadMoreButton.vue";
export default {
  name: "SingleCareerBox",
  components: ReadMoreButton,
  props: {
    job: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
    },
  },
};

import { render, staticRenderFns } from "./ClosedAccordionSection.vue?vue&type=template&id=6d5ee004&scoped=true&"
import script from "./ClosedAccordionSection.vue?vue&type=script&lang=js&"
export * from "./ClosedAccordionSection.vue?vue&type=script&lang=js&"
import style0 from "./ClosedAccordionSection.vue?vue&type=style&index=0&id=6d5ee004&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d5ee004",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LinkButton: require('/app/components/LinkButton.vue').default,ExternLink: require('/app/components/ExternLink.vue').default})

import { render, staticRenderFns } from "./HeroSectionDefault.vue?vue&type=template&id=58c39120&scoped=true&"
import script from "./HeroSectionDefault.vue?vue&type=script&lang=js&"
export * from "./HeroSectionDefault.vue?vue&type=script&lang=js&"
import style0 from "./HeroSectionDefault.vue?vue&type=style&index=0&id=58c39120&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58c39120",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LinkButton: require('/app/components/LinkButton.vue').default,MailtoButton: require('/app/components/MailtoButton.vue').default,ImageComponent: require('/app/components/ImageComponent.vue').default})

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VideoElementAutoplay from "./VideoElementAutoplay.vue";
export default {
  components: {
    VideoElementAutoplay,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SingleArchivePost from "./SingleArchivePost.vue";
import { ScrollTrigger } from "gsap/ScrollTrigger";
export default {
  name: "ArchiveSection",
  components: {
    SingleArchivePost,
  },
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      posts: this.block.blog_posts.data,
      searchKey: "",
      currentPage: 1,
      itemsPerPage: this.block.items_per_page ? this.block.items_per_page : 5,
      resultCount: 0,
    };
  },
  computed: {
    totalPages: function () {
      return Math.ceil(this.resultCount / this.itemsPerPage);
    },

    paginate: function () {
      if (!this.posts || this.posts.length != this.posts.length) {
        return;
      }
      this.resultCount = this.posts.length;
      if (this.currentPage >= this.totalPages) {
        this.currentPage = this.totalPages;
      }
      var index = this.currentPage * this.itemsPerPage - this.itemsPerPage;

      return this.posts.slice(index, index + this.itemsPerPage);
    },
  },
  methods: {
    setPage: function (pageNumber) {
      this.currentPage = pageNumber;
      this.scrollToTopArchive();
    },
    beforeEnter(el) {
      el.style.opacity = 0;
      el.style.transform = "translateY(100px)";
    },
    enter(el, done) {
      this.$gsap.to(el, {
        opacity: 1,
        y: 0,
        duration: 1,
        onComplete: done,
        delay: el.dataset.index * 0.3,
        scrollTrigger: {
          trigger: `.archive-${this.block.id}`,
          start: "top 70%",
          end: "bottom",
          scrub: false,
        },
      });
    },
    scrollToTopArchive() {
      setTimeout(() => {
        this.$refs.archive.scrollIntoView({ behavior: "smooth" });
      }, 500);
    },
  },
  mounted() {
    ScrollTrigger.refresh();
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    /** The speed, the marquee should move, in px/s */
    pxPerSecond: {
      type: Number,
      default: 50,
    },
  },
  data() {
    return {
      marqueeTrackWidth: 0,
      marqueeRootWidth: 0,
      resizeObserver: null,
    };
  },
  computed: {
    seconds() {
      const duration = this.marqueeTrackWidth / this.pxPerSecond; // half the speed, as we duplicate all elements
      return duration;
    },
    active() {
      return this.marqueeTrackWidth > this.marqueeRootWidth;
    },
  },
  methods: {
    updateMarqueeWidth() {
      this.$nextTick(() => {
        if (this.$refs.marqueeTrack && this.$refs.marqueeRoot) {
          this.marqueeTrackWidth = this.$refs.marqueeTrack.clientWidth;
          this.marqueeRootWidth = this.$refs.marqueeRoot.clientWidth;
          this.$refs.marqueeRoot.style.setProperty("--seconds", `${this.seconds}s`);
        }
      });
    },
  },
  mounted() {
    this.resizeObserver = new ResizeObserver(this.updateMarqueeWidth);
    this.resizeObserver.observe(this.$refs.marqueeRoot);
    this.updateMarqueeWidth();
  },
  updated() {
    this.updateMarqueeWidth();
  },
  beforeDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "ImageComponent",
  props: {
    image: {
      type: Object,
      required: true,
    },
    defaultClass: {
      type: String,
    },
    imageSize: {
      type: String,
    },
    lazy: {
      type: Boolean,
      default: true,
    },
  },
};

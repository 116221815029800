//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "SinglePacket",
  props: {
    packet: {
      type: Object,
      required: true,
    },
    block: {
      type: Object,
      required: true,
    },
  },
};

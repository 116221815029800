//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Spinner from "../Spinner.vue";
export default {
  components: Spinner,
  data() {
    return {
      submit: "Contact",
      name: "Name*",
      phone: "Phone",
      request: "Callback Request",
      email: "Email*",
      message: "Your message*",
      privacyText: "I accept the privacy policy*",
      // form data from here
      currentUrl: process.env.api_url + "/en",
      emailRoute: process.env.email_route,
      emailFrom: process.env.email_from,
      emailTo: process.env.email_to_sales,
      loading: false,
      success: false,
      error: false,
      form: {
        name: "",
        email: "",
        phone: "",
        date: "",
        message: "",
        privacy: "",
        hp: false,
        hp2: null,
        hp3: null,
      },
    };
  },
  methods: {
    async submitForm(e) {
      if (
        this.form.hp !== false ||
        this.form.hp2 !== null ||
        this.form.hp3 !== null
      ) {
        this.form = {
          name: "",
          email: "",
          phone: "",
          date: "",
          message: "",
          privacy: "",
        };

        alert("Thank you for submission!");
      } else {
        try {
          // console.log("FORM: ", this.form);

          // activate loading animation during the POST request
          this.loading = true;
          // change form.date from american to european style
          var americanDate = this.form.date && this.form.date.split("-");
          var europeanDate =
            americanDate &&
            americanDate[2] + "." + americanDate[1] + "." + americanDate[0];
          // insert all values in the var data that will be sent in the email
          var data = {
            subject: "Contact form Techcast english site",
            from: this.emailFrom,
            to: this.emailTo,
            html: `
            <div style='padding: 1rem'>
            <h1>Techcast contact form english site</h1>
            <p></p>
            <p>The contact form has been filled in on this page: <strong>${this.currentUrl}</strong></p>
            <p></p>
            <p>You have a new message from <strong>${this.form.name}</strong> erhalten.</p>
            <p></p>
            <ul>
            <li>Email: <strong>${this.form.email}</strong></li>
            <li>Phone: <strong>${this.form.phone}</strong></li>
            <li>Call request: <strong>${europeanDate}</strong></li>
            </ul>
            <p></p>
            <h3>Message:</h3>
            <p>${this.form.message}</p>
            </div>
            `,
          };
          // axios request headers
          const emailHeaders = {
            "Content-Type": "application/json",
          };
          // axios POST request to send the email
          await this.$axios
            .$post(this.emailRoute, JSON.stringify(data), {
              headers: emailHeaders,
            })
            .then(
              (result) => {
                this.loading = false;
                this.success = true;
                // console.log("SUCCESS!", result.text);
              },
              (error) => {
                this.loading = false;
                this.error = true;
                // console.log("FAILED...", error.text);
              }
            );
        } catch (error) {
          console.log({ error });
        }
        // Reset form field
        this.form = {
          name: "",
          email: "",
          phone: "",
          date: "",
          message: "",
          privacy: "",
        };
      }
    },
    dateDisabled(ymd, date) {
      // Disable weekends (Sunday = `0`, Saturday = `6`)
      const weekday = date.getDay();
      // 0 = sunday, 6 = saturday
      return weekday === 0 || weekday === 6;
    },
  },
};

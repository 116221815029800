//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import GradientLink from "~/components/english-site/GradientLink.vue";
export default {
  components: {
    GradientLink,
  },
  props: {
    headline: {
      type: String,
      required: true,
    },
    cta: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      centerCurrentTab: 0,
    };
  },
  mounted() {
    window.addEventListener("resize", this.updateIndicatorPosition);
    this.updateIndicatorPosition(); // Ensure it's positioned initially
  },
  destroyed() {
    window.removeEventListener("resize", this.updateIndicatorPosition);
  },
  methods: {
    updateIndicatorPosition() {
      this.$nextTick(() => {
        setTimeout(() => {
          // Small delay to ensure Bootstrap has updated
          const activeTab = this.$el.querySelector(".nav-link.active");
          const indicator = this.$refs.indicator;
          if (activeTab && indicator) {
            const { top, height } = activeTab.getBoundingClientRect();
            const parentTop = this.$el
              .querySelector(".tabs")
              .getBoundingClientRect().top;
            indicator.style.top = `${top - parentTop}px`;
            indicator.style.height = `${height}px`; // Adjust height if needed
          }
        }, 10); // Give Bootstrap time to switch the active class
      });
    },
  },
};

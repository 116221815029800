//
//
//
//
//
//
//
//

export default {
  name: "Spinner",
  props: {
    className: {
      type: String
    },
  },
};

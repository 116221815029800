var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.block.hidden)?_c('div',{ref:"archive",staticClass:"section archive position-relative",class:'archive-' + _vm.block.id},[_c('div',{staticClass:"background-dots"}),_vm._v(" "),_c('div',{staticClass:"container"},[_c('div',{staticClass:"mb-5"},[(_vm.block.headline)?_c('h3',{domProps:{"innerHTML":_vm._s(_vm.block.headline)}}):_vm._e()]),_vm._v(" "),_c('transition-group',{staticClass:"row",attrs:{"appear":"","tag":"div"},on:{"before-enter":_vm.beforeEnter,"enter":_vm.enter}},_vm._l((_vm.paginate),function(post,index){return _c('div',{key:post.id,staticClass:"col-12 px-0",attrs:{"data-index":index}},[_c('single-archive-post',{attrs:{"block":_vm.block,"post":post}})],1)}),0),_vm._v(" "),(_vm.totalPages > 1)?_c('ul',{staticClass:"pagination"},_vm._l((_vm.totalPages),function(pageNumber,index){return _c('li',{key:index},[(
            Math.abs(pageNumber - _vm.currentPage) < 3 ||
            pageNumber == _vm.totalPages ||
            pageNumber == 1
          )?_c('a',{key:pageNumber,class:{
            current: _vm.currentPage === pageNumber,
            last:
              pageNumber == _vm.totalPages &&
              Math.abs(pageNumber - _vm.currentPage) > 3,
            first: pageNumber == 1 && Math.abs(pageNumber - _vm.currentPage) > 3,
          },on:{"click":function($event){return _vm.setPage(pageNumber)}}},[_vm._v(_vm._s(pageNumber))]):_vm._e()])}),0):_vm._e()],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
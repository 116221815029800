//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Spinner from "./Spinner.vue";
export default {
  name: "ContactForm",
  components: Spinner,
  props: {
    block: {
      type: Object,
    },
  },
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const minDate = new Date(today);
    return {
      min: today.toISOString().split("T")[0],
      currentUrl: process.env.api_url + this.$route.path,
      emailRoute: process.env.email_route,
      emailFrom: process.env.email_from,
      emailTo: process.env.email_to_sales,
      loading: false,
      success: false,
      error: false,
      newsletter: false,
      form: {
        name: "",
        email: "",
        phone: "",
        date: "",
        acceptNewsletter: "",
        message: "",
        privacy: "",
        hp: false,
        hp2: null,
        hp3: null,
      },
    };
  },
  watch: {
    newsletter: function (value) {
      this.form.acceptNewsletter = value
        ? "Der Kunde möchte sich beim Newsletter anmelden"
        : false;
    },
  },
  methods: {
    async formSubmit(e) {
      if (
        this.form.hp !== false ||
        this.form.hp2 !== null ||
        this.form.hp3 !== null
      ) {
        this.form = {
          name: "",
          email: "",
          phone: "",
          date: "",
          acceptNewsletter: "",
          message: "",
          privacy: "",
        };

        alert("Thank you for submission!");
      } else {
        try {
          // console.log("FORM: ", this.form);

          // activate loading animation during the POST request
          this.loading = true;
          // change form.date from american to european style
          var americanDate = this.form.date && this.form.date.split("-");
          var europeanDate =
            americanDate &&
            americanDate[2] + "." + americanDate[1] + "." + americanDate[0];
          // insert all values in the var newsletterData that will be sent to the newsletter portal
          var newsletterData = {
            email: this.form.email,
            name: this.form.name,
          };
          // insert all values in the var data that will be sent in the email
          var data = {
            subject: "Kontaktformular",
            from: this.emailFrom,
            to: this.emailTo,
            html: `
            <div style='padding: 1rem'>
            <h1>Techcast Kontaktformular</h1>
            <p></p>
            <p>Das Kontaktformular wurde in dieser Seite ausgefüllt: <strong>${
              this.currentUrl
            }</strong></p>
            <p></p>
            <p>du hast eine Nachricht von <strong>${
              this.form.name
            }</strong> erhalten.</p>
            <p></p>
            <ul>
            <li>Email: <strong>${this.form.email}</strong></li>
            <li>Telefonnummer: <strong>${this.form.phone}</strong></li>
            <li>Rückrufwunsch: <strong>${europeanDate}</strong></li>
            ${
              this.form.acceptNewsletter
                ? "<li><strong>" + this.form.acceptNewsletter + "</strong></li>"
                : ""
            }
            </ul>
            <p></p>
            <h3>Nachricht:</h3>
            <p>${this.form.message}</p>
            </div>
            `,
          };
          // axios request headers
          const emailHeaders = {
            "Content-Type": "application/json",
          };
          // axios POST request if the User registered for the newsletter
          this.newsletter &&
            (await this.$axios
              .$post(process.env.NEWSLETTER_ROUTE, newsletterData)
              .then(
                (result) => {
                  this.success = true;
                  // console.log("SUCCESS!", result.text);
                },
                (error) => {
                  this.error = true;
                  // console.log("FAILED...", error.text);
                }
              ));
          // axios POST request to send the email
          await this.$axios
            .$post(this.emailRoute, JSON.stringify(data), {
              headers: emailHeaders,
            })
            .then(
              (result) => {
                this.success = true;
                this.$router.push("/kontaktformular-vielen-dank");
                // console.log("SUCCESS!", result.text);
              },
              (error) => {
                this.loading = false;
                this.error = true;
                // console.log("FAILED...", error.text);
              }
            );
        } catch (error) {
          console.log({ error });
        }
        // Reset form field
        this.form = {
          name: "",
          email: "",
          phone: "",
          date: "",
          acceptNewsletter: "",
          message: "",
          privacy: "",
        };
      }
    },
    dateDisabled(ymd, date) {
      // Disable weekends (Sunday = `0`, Saturday = `6`) and
      // const day = date.getDate();
      // disable days that fall on the 13th of the month (return day === 13)
      const weekday = date.getDay();
      // Return `true` if the date should be disabled
      return weekday === 0 || weekday === 6;
    },
  },
};

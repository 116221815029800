import { render, staticRenderFns } from "./FeaturesSection.vue?vue&type=template&id=95d1ed22&scoped=true&"
import script from "./FeaturesSection.vue?vue&type=script&lang=js&"
export * from "./FeaturesSection.vue?vue&type=script&lang=js&"
import style0 from "./FeaturesSection.vue?vue&type=style&index=0&id=95d1ed22&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "95d1ed22",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SquareItem: require('/app/components/SquareItem.vue').default,SingleFeature: require('/app/components/SingleFeature.vue').default,FeaturesSlider: require('/app/components/FeaturesSlider.vue').default,LinkButton: require('/app/components/LinkButton.vue').default})

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/img/grey-dots.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".packets{margin-bottom:7rem}.packets .background-dots{position:absolute;top:-4rem;left:0;width:100%;height:90%;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:8rem;background-repeat:repeat;z-index:-1}@media(min-width:768px){.packets .background-dots{background-size:13rem}}.packets .outter-container{background-color:#fff;width:calc(100% - 30px);margin:0 auto;padding:3rem 15px}@media(min-width:768px){.packets .outter-container{width:90%;padding:3rem 2rem}}.packets .cta-bottom{background-color:var(--lightgrey-price-box);padding:2.5rem}@media(min-width:768px){.packets .cta-bottom h3{font-size:32px}}@media(min-width:768px){.packets .cta-bottom p{font-size:20px}}.packets .cta-bottom .button{border:none;text-transform:capitalize;font-family:var(--font-bold)}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;

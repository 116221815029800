// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/img/grey-dots.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "html[data-v-32b63ab8]{scroll-behavior:smooth}.archive[data-v-32b63ab8]{margin-bottom:7rem}.archive .background-dots[data-v-32b63ab8]{position:absolute;top:-4rem;left:0;width:100%;height:90%;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:8rem;background-repeat:repeat;z-index:-1}@media(min-width:768px){.archive .background-dots[data-v-32b63ab8]{background-size:13rem}}.archive .container[data-v-32b63ab8]{padding-top:3rem;padding-bottom:3rem}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;

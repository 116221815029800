//
//
//
//
//
//
//
//
//

import TimelineRoot from "~/components/english-site/TimelineRoot.vue";
import TimelineStep from "~/components/english-site/TimelineStep.vue";

export default {
  components: {
		TimelineRoot,
		TimelineStep
  },
  props: {
    headline: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
};

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/img/grey-dots.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".references[data-v-c5ce8a0e]{margin-bottom:7rem}.references .background-dots[data-v-c5ce8a0e]{position:absolute;top:-4rem;left:0;width:100%;height:70%;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:8rem;background-repeat:repeat;z-index:-1}@media(min-width:768px){.references .background-dots[data-v-c5ce8a0e]{background-size:13rem}}.references .outter-container[data-v-c5ce8a0e]{width:calc(100% - 30px);margin:0 auto}.references .outter-container .container[data-v-c5ce8a0e]{background-color:#fff;padding:3rem 15px}@media(min-width:768px){.references .outter-container .container[data-v-c5ce8a0e]{width:90%;padding:3rem 2rem}}.references .logo[data-v-c5ce8a0e]{filter:grayscale(100%);-webkit-filter:grayscale(100%);transition-duration:.3s}.references .logo[data-v-c5ce8a0e]:hover{-webkit-filter:grayscale(0)}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;

//
//
//
//
//
//
//
//
//

import RequestForm from "~/components/english-site/RequestForm.vue";
import FormImages from "../FormImages.vue";
export default {
  name: "SectionContact",
  components: {
    RequestForm,
    FormImages,
  },
  props: {
    headline: {
      type: String,
      required: true,
    },
    product: {
      type: String,
      required: true,
    },
  },
};

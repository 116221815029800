//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LinkButton from "./LinkButton.vue";
import VideoComponent from "./VideoComponent.vue";

export default {
  name: "HeroSectionHomeAnim",
  components: { VideoComponent, LinkButton },
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      headlineMinimized: false,
    };
  },
  methods: {
    autoplayVideo() {
      this.$refs.videoComponent.playVideo();
    },
  },
  mounted() {
    this.autoplayVideo();
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    media: {
      type: Object,
      required: true,
    },
    autoplay: {
      type: Boolean,
      default: false,
      required: false,
    },
    type: {
      type: String,
      default: "image",
      required: false,
    },
  },
  data() {
    return {
      url: process.env.api_url,
      videoLoaded: false,
    };
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SinglePresseBox from "./SinglePresseBox.vue";
export default {
  name: "PresseSection",
  components: {
    SinglePresseBox,
  },
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      posts: this.block.presse_posts.data,
      searchKey: "",
      currentPage: 1,
      itemsPerPage: this.block.items_per_page ? this.block.items_per_page : 5,
      resultCount: 0,
    };
  },
  computed: {
    totalPages: function () {
      return Math.ceil(this.resultCount / this.itemsPerPage);
    },

    paginate: function () {
      const totalOPosts = this.posts.sort((a, b) => b.id - a.id);
      if (!totalOPosts || totalOPosts.length != totalOPosts.length) {
        return;
      }
      this.resultCount = totalOPosts.length;
      if (this.currentPage >= this.totalPages) {
        this.currentPage = this.totalPages;
      }
      var index = this.currentPage * this.itemsPerPage - this.itemsPerPage;
      return totalOPosts.slice(index, index + this.itemsPerPage);
    },
  },
  methods: {
    setPage: function (pageNumber) {
      this.currentPage = pageNumber;
      this.scrollToTopPresse();
    },
    beforeEnter(el) {
      el.style.opacity = 0;
      el.style.transform = "translateY(100px)";
    },
    enter(el, done) {
      this.$gsap.to(el, {
        opacity: 1,
        y: 0,
        duration: 1,
        onComplete: done,
        delay: el.dataset.index * 0.3,
        scrollTrigger: {
          trigger: `.presse-${this.block.id}`,
          start: "top 70%",
          end: "bottom",
          scrub: false,
        },
      });
    },
    scrollToTopPresse() {
      setTimeout(() => {
        this.$refs.presse.scrollIntoView({ behavior: "smooth" });
      }, 500);
    },
  },
};

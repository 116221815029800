//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import GradientLink from "~/components/english-site/GradientLink.vue";
import LogosCarousel from "./LogosCarousel.vue";
import VideoComponent from "../VideoComponent.vue";
import Media from "./Media.vue";
import VideoElementAutoplay from "./VideoElementAutoplay.vue";
export default {
  components: {
    GradientLink,
    LogosCarousel,
    VideoComponent,
    Media,
    VideoElementAutoplay,
  },
  props: {
    cta: {
      type: String,
      default: "",
    },
    headline: {
      type: String,
      default: "",
    },
    product: {
      type: String,
      default: "",
    },
    content: {
      type: String,
      default: "",
    },
    heroMedia: {
      type: Object,
      default: () => ({}),
    },
    partnerHeadline: {
      type: String,
      default: "",
    },
    partners: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: "image",
      required: false,
    },
    autoplay: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      url: process.env.api_url,
      showMarquee: true,
      showMarqueeMediaQuery: undefined,
      showPlayButton: false,
    };
  },
  beforeMount() {
    this.showMarqueeMediaQuery = window.matchMedia("(min-width: 815px)");
    this.showMarqueeMediaQuery.addEventListener("change", this.onMediaChange);
    this.showMarquee = this.showMarqueeMediaQuery.matches;
  },
  destroyed() {
    if (this.showMarqueeMediaQuery) {
      this.showMarqueeMediaQuery.removeEventListener(
        "change",
        this.onMediaChange
      );
    }
  },
  methods: {
    onMediaChange(event) {
      this.showMarquee = event.matches;
    },
    formatHeadline(text) {
      return text.replace(/\./g, ".\u200B"); // Insert zero-width space after each dot
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    imprint: {
      type: String,
      default: "/en/imprint",
    },
    dataPrivacy: {
      type: String,
      default: "/en/data-privacy",
    },
    podcast: {
      type: String,
      default: "https://www.techcast.tv/de",
    },
    youtube: {
      type: String,
      default: "https://www.youtube.com/channel/UCQ0qt6fBplaws-x87Xrodlw",
    },
    instagram: {
      type: String,
      default: "https://www.instagram.com/techcast.gmbh",
    },
    linkedIn: {
      type: String,
      default: "https://de.linkedin.com/company/techcast-gmbh",
    },
  },
  data() {
    return {
      year: "",
    };
  },
  mounted() {
    this.year = new Date().getFullYear();
  },
  methods: {
    async removeAllCookies() {
      // remove 'cookies' from Youtube in the Local Storage
      localStorage.setItem("acceptedYoutubePolicy", false);
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key.indexOf("yt") === 0) {
          localStorage.removeItem(key);
        }
      }

      var cookiesToDelete = [];
      // --- window.cookieStore for Google and Android | document.cookie for IOS and Firefox --- //
      const allCookies = window.cookieStore
        ? await window.cookieStore.getAll()
        : document.cookie.split(";");

      if (allCookies !== undefined && allCookies.length) {
        await allCookies.map((cookie) =>
          cookie && cookie.name ? cookiesToDelete.push(cookie.name) : false
        );
        cookiesToDelete.length &&
          (await cookiesToDelete.map(
            (cookie) => cookie && window.cookieStore.delete(cookie)
          ));
        document.cookie &&
          document.cookie.split(";").forEach(function (c) {
            document.cookie = c
              .replace(/^ +/, "")
              .replace(
                /=.*/,
                "=;expires=" + new Date().toUTCString() + ";path=/"
              );
          });
      }
      window.cookieStore
        ? (await window.cookieStore) &&
          window.cookieStore.set("cookie_control_consent", true)
        : (document.cookie = "cookie_control_consent=true");
      window.location.href = window.location.href;
      return;
    },
  },
};

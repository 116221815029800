//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    color: {
      type: String,
      default: "green",
    },
    to: {
      type: String,
      required: true,
    },
  },
};

import { render, staticRenderFns } from "./TeamMember.vue?vue&type=template&id=f3ab9fd2&"
import script from "./TeamMember.vue?vue&type=script&lang=js&"
export * from "./TeamMember.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PhoneButton: require('/app/components/PhoneButton.vue').default,MailtoButton: require('/app/components/MailtoButton.vue').default})

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ScrollTrigger } from "gsap/ScrollTrigger";
export default {
  name: "TitleSection",
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    ScrollTrigger.refresh();
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VideoComponent from "./VideoComponent.vue";
import LinkButton from "./LinkButton.vue";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ImageComponent from "./ImageComponent.vue";
export default {
  name: "LaptopSection",
  components: { LinkButton, VideoComponent, ImageComponent },
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      acceptedYoutubePolicy: this.initAcceptedYoutubePolicyIsChecked(),
      showYoutubeVideos: this.$store.state.acceptedYoutubePolicy,
    };
  },
  watch: {
    "$store.state.acceptedYoutubePolicy": function () {
      this.acceptedYoutubePolicy = this.$store.state.acceptedYoutubePolicy;
      this.showYoutubeVideos = this.$store.state.acceptedYoutubePolicy;
    },
  },
  methods: {
    initAcceptedYoutubePolicyIsChecked: function () {
      if (process.client) {
        const stored = localStorage.getItem("acceptedYoutubePolicy");
        if (stored != "true") {
          // console.log("Nothing stored; default to `false`");
          this.$store.commit("acceptedYoutubePolicy", false);
          this.showYoutubeVideos = false;
          return false;
        } else {
          // console.log("Using stored value " + stored);
          this.$store.commit("acceptedYoutubePolicy", true);
          this.showYoutubeVideos = true;
          return true;
        }
      }
      return false; // Fallback value for SSR
    },
    displayYoutubeVideo(e) {
      this.$store.commit("acceptedYoutubePolicy", e);
      this.showYoutubeVideos = true;
    },
    laptopIn() {
      if (
        [...document.getElementsByClassName("laptop-background__wrapper")]
          .length > 0
      ) {
        this.$gsap.fromTo(
          `.laptop-background__wrapper-${this.block.id}`,
          {
            x: 300,
            opacity: 0,
          },
          {
            x: 0,
            opacity: 1,
            duration: 0.8,
            scrollTrigger: {
              trigger: `.laptop-${this.block.id}`,
              start: "top 70%",
            },
          }
        );
      }
    },
    laptopOnScroll() {
      if (
        [...document.getElementsByClassName("laptop-background__wrapper")]
          .length > 0
      ) {
        this.$gsap.to(`.laptop-background__wrapper-${this.block.id}`, {
          y: -100,
          delay: 1.5,
          scrollTrigger: {
            trigger: `.laptop-${this.block.id}`,
            start: "top 50%",
            scrub: true,
          },
        });
      }
    },
    laptopSectionOut() {
      if ([...document.getElementsByClassName("laptop-video")].length > 0) {
        this.$gsap.to(`.laptop-${this.block.id}`, {
          opacity: 0,
          duration: 1.5,
        });
      }
    },
    heroBodyIn() {
      if ([...document.getElementsByClassName("body-animation")].length > 0) {
        this.$gsap.fromTo(
          `.body-animation-${this.block.id}`,
          {
            opacity: 0,
            x: -50,
          },
          {
            opacity: 1,
            x: 0,
            duration: 0.8,
            scrollTrigger: {
              trigger: `.laptop-${this.block.id}`,
              start: "top 90%",
            },
          }
        );
      }
    },
    trianglesBottomIn() {
      if (
        [
          ...document.getElementsByClassName(
            `laptop__triangle-pattern__bottom-${this.block.id}`
          ),
        ].length > 0
      ) {
        this.$gsap.fromTo(
          `.laptop__triangle-pattern__bottom-${this.block.id}`,
          {
            opacity: 0,
            x: -200,
          },
          {
            x: 150,
            opacity: 1,
            scrollTrigger: {
              trigger: `.laptop-${this.block.id}`,
              start: "top 50%",
              end: "bottom -50%",
              scrub: true,
            },
          }
        );
      }
    },
  },
  mounted() {
    this.laptopIn();
    this.laptopOnScroll();
    this.heroBodyIn();
    this.trianglesBottomIn();
    ScrollTrigger.refresh();
  },
  beforeDestroy() {
    this.laptopSectionOut();
  },
};

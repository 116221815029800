export const AccordionSection = () => import('../../components/AccordionSection.vue' /* webpackChunkName: "components/accordion-section" */).then(c => wrapFunctional(c.default || c))
export const ApplyForm = () => import('../../components/ApplyForm.vue' /* webpackChunkName: "components/apply-form" */).then(c => wrapFunctional(c.default || c))
export const ArchiveSection = () => import('../../components/ArchiveSection.vue' /* webpackChunkName: "components/archive-section" */).then(c => wrapFunctional(c.default || c))
export const BoxesBreakBlock = () => import('../../components/BoxesBreakBlock.vue' /* webpackChunkName: "components/boxes-break-block" */).then(c => wrapFunctional(c.default || c))
export const BreakBlockBoxesSection = () => import('../../components/BreakBlockBoxesSection.vue' /* webpackChunkName: "components/break-block-boxes-section" */).then(c => wrapFunctional(c.default || c))
export const BreakBlockSection = () => import('../../components/BreakBlockSection.vue' /* webpackChunkName: "components/break-block-section" */).then(c => wrapFunctional(c.default || c))
export const CareerSection = () => import('../../components/CareerSection.vue' /* webpackChunkName: "components/career-section" */).then(c => wrapFunctional(c.default || c))
export const CaseStudiesSection = () => import('../../components/CaseStudiesSection.vue' /* webpackChunkName: "components/case-studies-section" */).then(c => wrapFunctional(c.default || c))
export const ClosedAccordionSection = () => import('../../components/ClosedAccordionSection.vue' /* webpackChunkName: "components/closed-accordion-section" */).then(c => wrapFunctional(c.default || c))
export const CompanySection = () => import('../../components/CompanySection.vue' /* webpackChunkName: "components/company-section" */).then(c => wrapFunctional(c.default || c))
export const ContactEmail = () => import('../../components/ContactEmail.vue' /* webpackChunkName: "components/contact-email" */).then(c => wrapFunctional(c.default || c))
export const ContactForm = () => import('../../components/ContactForm.vue' /* webpackChunkName: "components/contact-form" */).then(c => wrapFunctional(c.default || c))
export const ContactSection = () => import('../../components/ContactSection.vue' /* webpackChunkName: "components/contact-section" */).then(c => wrapFunctional(c.default || c))
export const DownloadButton = () => import('../../components/DownloadButton.vue' /* webpackChunkName: "components/download-button" */).then(c => wrapFunctional(c.default || c))
export const ExternLink = () => import('../../components/ExternLink.vue' /* webpackChunkName: "components/extern-link" */).then(c => wrapFunctional(c.default || c))
export const FeaturesNeuSection = () => import('../../components/FeaturesNeuSection.vue' /* webpackChunkName: "components/features-neu-section" */).then(c => wrapFunctional(c.default || c))
export const FeaturesRowsSection = () => import('../../components/FeaturesRowsSection.vue' /* webpackChunkName: "components/features-rows-section" */).then(c => wrapFunctional(c.default || c))
export const FeaturesSection = () => import('../../components/FeaturesSection.vue' /* webpackChunkName: "components/features-section" */).then(c => wrapFunctional(c.default || c))
export const FeaturesSlider = () => import('../../components/FeaturesSlider.vue' /* webpackChunkName: "components/features-slider" */).then(c => wrapFunctional(c.default || c))
export const FormImages = () => import('../../components/FormImages.vue' /* webpackChunkName: "components/form-images" */).then(c => wrapFunctional(c.default || c))
export const HeaderColor = () => import('../../components/HeaderColor.vue' /* webpackChunkName: "components/header-color" */).then(c => wrapFunctional(c.default || c))
export const HeroHomeAnim = () => import('../../components/HeroHomeAnim.vue' /* webpackChunkName: "components/hero-home-anim" */).then(c => wrapFunctional(c.default || c))
export const HeroSectionDefault = () => import('../../components/HeroSectionDefault.vue' /* webpackChunkName: "components/hero-section-default" */).then(c => wrapFunctional(c.default || c))
export const HeroSectionHomepage = () => import('../../components/HeroSectionHomepage.vue' /* webpackChunkName: "components/hero-section-homepage" */).then(c => wrapFunctional(c.default || c))
export const HeroSectionVideo = () => import('../../components/HeroSectionVideo.vue' /* webpackChunkName: "components/hero-section-video" */).then(c => wrapFunctional(c.default || c))
export const ImageComponent = () => import('../../components/ImageComponent.vue' /* webpackChunkName: "components/image-component" */).then(c => wrapFunctional(c.default || c))
export const ImageGreen = () => import('../../components/ImageGreen.vue' /* webpackChunkName: "components/image-green" */).then(c => wrapFunctional(c.default || c))
export const ImagePlayButtons = () => import('../../components/ImagePlayButtons.vue' /* webpackChunkName: "components/image-play-buttons" */).then(c => wrapFunctional(c.default || c))
export const ImageRed = () => import('../../components/ImageRed.vue' /* webpackChunkName: "components/image-red" */).then(c => wrapFunctional(c.default || c))
export const ImageSection = () => import('../../components/ImageSection.vue' /* webpackChunkName: "components/image-section" */).then(c => wrapFunctional(c.default || c))
export const ImageSeparator = () => import('../../components/ImageSeparator.vue' /* webpackChunkName: "components/image-separator" */).then(c => wrapFunctional(c.default || c))
export const ImageTeam = () => import('../../components/ImageTeam.vue' /* webpackChunkName: "components/image-team" */).then(c => wrapFunctional(c.default || c))
export const LaptopSection = () => import('../../components/LaptopSection.vue' /* webpackChunkName: "components/laptop-section" */).then(c => wrapFunctional(c.default || c))
export const LinkButton = () => import('../../components/LinkButton.vue' /* webpackChunkName: "components/link-button" */).then(c => wrapFunctional(c.default || c))
export const LoadingSpinner = () => import('../../components/LoadingSpinner.vue' /* webpackChunkName: "components/loading-spinner" */).then(c => wrapFunctional(c.default || c))
export const LogosSection = () => import('../../components/LogosSection.vue' /* webpackChunkName: "components/logos-section" */).then(c => wrapFunctional(c.default || c))
export const MailtoButton = () => import('../../components/MailtoButton.vue' /* webpackChunkName: "components/mailto-button" */).then(c => wrapFunctional(c.default || c))
export const MenuItem = () => import('../../components/MenuItem.vue' /* webpackChunkName: "components/menu-item" */).then(c => wrapFunctional(c.default || c))
export const NewsSlider = () => import('../../components/NewsSlider.vue' /* webpackChunkName: "components/news-slider" */).then(c => wrapFunctional(c.default || c))
export const NewsletterInput = () => import('../../components/NewsletterInput.vue' /* webpackChunkName: "components/newsletter-input" */).then(c => wrapFunctional(c.default || c))
export const NewsletterSection = () => import('../../components/NewsletterSection.vue' /* webpackChunkName: "components/newsletter-section" */).then(c => wrapFunctional(c.default || c))
export const PacketsSection = () => import('../../components/PacketsSection.vue' /* webpackChunkName: "components/packets-section" */).then(c => wrapFunctional(c.default || c))
export const Page = () => import('../../components/Page.vue' /* webpackChunkName: "components/page" */).then(c => wrapFunctional(c.default || c))
export const PdfSection = () => import('../../components/PdfSection.vue' /* webpackChunkName: "components/pdf-section" */).then(c => wrapFunctional(c.default || c))
export const PhoneButton = () => import('../../components/PhoneButton.vue' /* webpackChunkName: "components/phone-button" */).then(c => wrapFunctional(c.default || c))
export const PostBody = () => import('../../components/PostBody.vue' /* webpackChunkName: "components/post-body" */).then(c => wrapFunctional(c.default || c))
export const PostImage = () => import('../../components/PostImage.vue' /* webpackChunkName: "components/post-image" */).then(c => wrapFunctional(c.default || c))
export const PostLink = () => import('../../components/PostLink.vue' /* webpackChunkName: "components/post-link" */).then(c => wrapFunctional(c.default || c))
export const PresseSection = () => import('../../components/PresseSection.vue' /* webpackChunkName: "components/presse-section" */).then(c => wrapFunctional(c.default || c))
export const QuotesSection = () => import('../../components/QuotesSection.vue' /* webpackChunkName: "components/quotes-section" */).then(c => wrapFunctional(c.default || c))
export const ReadMoreButton = () => import('../../components/ReadMoreButton.vue' /* webpackChunkName: "components/read-more-button" */).then(c => wrapFunctional(c.default || c))
export const ReferencesSection = () => import('../../components/ReferencesSection.vue' /* webpackChunkName: "components/references-section" */).then(c => wrapFunctional(c.default || c))
export const ScrollTopButton = () => import('../../components/ScrollTopButton.vue' /* webpackChunkName: "components/scroll-top-button" */).then(c => wrapFunctional(c.default || c))
export const SeparatorSection = () => import('../../components/SeparatorSection.vue' /* webpackChunkName: "components/separator-section" */).then(c => wrapFunctional(c.default || c))
export const SeparatorWhite = () => import('../../components/SeparatorWhite.vue' /* webpackChunkName: "components/separator-white" */).then(c => wrapFunctional(c.default || c))
export const SingleArchivePost = () => import('../../components/SingleArchivePost.vue' /* webpackChunkName: "components/single-archive-post" */).then(c => wrapFunctional(c.default || c))
export const SingleCareerBox = () => import('../../components/SingleCareerBox.vue' /* webpackChunkName: "components/single-career-box" */).then(c => wrapFunctional(c.default || c))
export const SingleCaseStudy = () => import('../../components/SingleCaseStudy.vue' /* webpackChunkName: "components/single-case-study" */).then(c => wrapFunctional(c.default || c))
export const SingleCompanyPost = () => import('../../components/SingleCompanyPost.vue' /* webpackChunkName: "components/single-company-post" */).then(c => wrapFunctional(c.default || c))
export const SingleFeature = () => import('../../components/SingleFeature.vue' /* webpackChunkName: "components/single-feature" */).then(c => wrapFunctional(c.default || c))
export const SingleFeatureRow = () => import('../../components/SingleFeatureRow.vue' /* webpackChunkName: "components/single-feature-row" */).then(c => wrapFunctional(c.default || c))
export const SinglePacket = () => import('../../components/SinglePacket.vue' /* webpackChunkName: "components/single-packet" */).then(c => wrapFunctional(c.default || c))
export const SinglePresseBox = () => import('../../components/SinglePresseBox.vue' /* webpackChunkName: "components/single-presse-box" */).then(c => wrapFunctional(c.default || c))
export const SingleSocialPost = () => import('../../components/SingleSocialPost.vue' /* webpackChunkName: "components/single-social-post" */).then(c => wrapFunctional(c.default || c))
export const SocialSection = () => import('../../components/SocialSection.vue' /* webpackChunkName: "components/social-section" */).then(c => wrapFunctional(c.default || c))
export const Spinner = () => import('../../components/Spinner.vue' /* webpackChunkName: "components/spinner" */).then(c => wrapFunctional(c.default || c))
export const SquareItem = () => import('../../components/SquareItem.vue' /* webpackChunkName: "components/square-item" */).then(c => wrapFunctional(c.default || c))
export const TabletSection = () => import('../../components/TabletSection.vue' /* webpackChunkName: "components/tablet-section" */).then(c => wrapFunctional(c.default || c))
export const TeamMember = () => import('../../components/TeamMember.vue' /* webpackChunkName: "components/team-member" */).then(c => wrapFunctional(c.default || c))
export const TeamSection = () => import('../../components/TeamSection.vue' /* webpackChunkName: "components/team-section" */).then(c => wrapFunctional(c.default || c))
export const TextSlider = () => import('../../components/TextSlider.vue' /* webpackChunkName: "components/text-slider" */).then(c => wrapFunctional(c.default || c))
export const TheFooterEnglish = () => import('../../components/TheFooter-english.vue' /* webpackChunkName: "components/the-footer-english" */).then(c => wrapFunctional(c.default || c))
export const TheFooter = () => import('../../components/TheFooter.vue' /* webpackChunkName: "components/the-footer" */).then(c => wrapFunctional(c.default || c))
export const TheFooterLegal = () => import('../../components/TheFooterLegal.vue' /* webpackChunkName: "components/the-footer-legal" */).then(c => wrapFunctional(c.default || c))
export const TheFooterSocial = () => import('../../components/TheFooterSocial.vue' /* webpackChunkName: "components/the-footer-social" */).then(c => wrapFunctional(c.default || c))
export const TheHeader = () => import('../../components/TheHeader.vue' /* webpackChunkName: "components/the-header" */).then(c => wrapFunctional(c.default || c))
export const TitleSection = () => import('../../components/TitleSection.vue' /* webpackChunkName: "components/title-section" */).then(c => wrapFunctional(c.default || c))
export const TwoBoxesSection = () => import('../../components/TwoBoxesSection.vue' /* webpackChunkName: "components/two-boxes-section" */).then(c => wrapFunctional(c.default || c))
export const VideoComponent = () => import('../../components/VideoComponent.vue' /* webpackChunkName: "components/video-component" */).then(c => wrapFunctional(c.default || c))
export const EnglishSiteArrowBackground = () => import('../../components/english-site/ArrowBackground.vue' /* webpackChunkName: "components/english-site-arrow-background" */).then(c => wrapFunctional(c.default || c))
export const EnglishSiteGradientLink = () => import('../../components/english-site/GradientLink.vue' /* webpackChunkName: "components/english-site-gradient-link" */).then(c => wrapFunctional(c.default || c))
export const EnglishSiteLogosCarousel = () => import('../../components/english-site/LogosCarousel.vue' /* webpackChunkName: "components/english-site-logos-carousel" */).then(c => wrapFunctional(c.default || c))
export const EnglishSiteMarquee = () => import('../../components/english-site/Marquee.vue' /* webpackChunkName: "components/english-site-marquee" */).then(c => wrapFunctional(c.default || c))
export const EnglishSiteMedia = () => import('../../components/english-site/Media.vue' /* webpackChunkName: "components/english-site-media" */).then(c => wrapFunctional(c.default || c))
export const EnglishSiteNavbar = () => import('../../components/english-site/Navbar.vue' /* webpackChunkName: "components/english-site-navbar" */).then(c => wrapFunctional(c.default || c))
export const EnglishSitePageFooter = () => import('../../components/english-site/PageFooter.vue' /* webpackChunkName: "components/english-site-page-footer" */).then(c => wrapFunctional(c.default || c))
export const EnglishSitePlayArrow = () => import('../../components/english-site/PlayArrow.vue' /* webpackChunkName: "components/english-site-play-arrow" */).then(c => wrapFunctional(c.default || c))
export const EnglishSiteRequestForm = () => import('../../components/english-site/RequestForm.vue' /* webpackChunkName: "components/english-site-request-form" */).then(c => wrapFunctional(c.default || c))
export const EnglishSiteSectionBasicFeatures = () => import('../../components/english-site/SectionBasicFeatures.vue' /* webpackChunkName: "components/english-site-section-basic-features" */).then(c => wrapFunctional(c.default || c))
export const EnglishSiteSectionContact = () => import('../../components/english-site/SectionContact.vue' /* webpackChunkName: "components/english-site-section-contact" */).then(c => wrapFunctional(c.default || c))
export const EnglishSiteSectionFaq = () => import('../../components/english-site/SectionFaq.vue' /* webpackChunkName: "components/english-site-section-faq" */).then(c => wrapFunctional(c.default || c))
export const EnglishSiteSectionFeatures = () => import('../../components/english-site/SectionFeatures.vue' /* webpackChunkName: "components/english-site-section-features" */).then(c => wrapFunctional(c.default || c))
export const EnglishSiteSectionHero = () => import('../../components/english-site/SectionHero.vue' /* webpackChunkName: "components/english-site-section-hero" */).then(c => wrapFunctional(c.default || c))
export const EnglishSiteSectionReferences = () => import('../../components/english-site/SectionReferences.vue' /* webpackChunkName: "components/english-site-section-references" */).then(c => wrapFunctional(c.default || c))
export const EnglishSiteSectionServices = () => import('../../components/english-site/SectionServices.vue' /* webpackChunkName: "components/english-site-section-services" */).then(c => wrapFunctional(c.default || c))
export const EnglishSiteSectionTimeline = () => import('../../components/english-site/SectionTimeline.vue' /* webpackChunkName: "components/english-site-section-timeline" */).then(c => wrapFunctional(c.default || c))
export const EnglishSiteSectionUseCases = () => import('../../components/english-site/SectionUseCases.vue' /* webpackChunkName: "components/english-site-section-use-cases" */).then(c => wrapFunctional(c.default || c))
export const EnglishSiteTimelineRoot = () => import('../../components/english-site/TimelineRoot.vue' /* webpackChunkName: "components/english-site-timeline-root" */).then(c => wrapFunctional(c.default || c))
export const EnglishSiteTimelineStep = () => import('../../components/english-site/TimelineStep.vue' /* webpackChunkName: "components/english-site-timeline-step" */).then(c => wrapFunctional(c.default || c))
export const EnglishSiteVariableCarousel = () => import('../../components/english-site/VariableCarousel.vue' /* webpackChunkName: "components/english-site-variable-carousel" */).then(c => wrapFunctional(c.default || c))
export const EnglishSiteVideoElementAutoplay = () => import('../../components/english-site/VideoElementAutoplay.vue' /* webpackChunkName: "components/english-site-video-element-autoplay" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}

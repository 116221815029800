// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/img/grey-dots.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".career[data-v-1c4b7694]{margin-bottom:0}.career .background-dots[data-v-1c4b7694]{position:absolute;top:-4rem;left:0;width:100%;height:75%;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:8rem;background-repeat:repeat;z-index:-1}@media(min-width:768px){.career .background-dots[data-v-1c4b7694]{background-size:13rem}}.career .outter-container[data-v-1c4b7694]{width:calc(100% - 30px);margin:0 auto}.career .outter-container .container[data-v-1c4b7694]{background-color:#fff;padding:3rem 15px}@media(min-width:768px){.career .outter-container .container[data-v-1c4b7694]{width:90%;padding:3rem 2rem}}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ImageComponent from "./ImageComponent.vue";
import LinkButton from "./LinkButton.vue";
export default {
  name: "TwoBoxesSection",
  components: { LinkButton, ImageComponent },
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  methods: {
    twoBoxesLeftIn() {
      if (
        [...document.getElementsByClassName("two-boxes__box--left")].length > 0
      ) {
        this.$gsap.from(".two-boxes__box--left", {
          x: -150,
          opacity: 0,
          duration: 0.8,
          scrollTrigger: {
            trigger: ".two-boxes__box--left",
            start: "top 90%",
            end: "bottom",
          },
        });
      }
      return;
    },
    twoBoxesLeftOut() {
      if (
        [...document.getElementsByClassName("two-boxes__box--left")].length > 0
      ) {
        this.$gsap.to(".two-boxes__box--left", {
          opacity: 0,
          x: -150,
        });
      }
      return;
    },
    twoBoxesRightIn() {
      if (
        [...document.getElementsByClassName("two-boxes__box--right")].length > 0
      ) {
        this.$gsap.from(".two-boxes__box--right", {
          x: 150,
          opacity: 0,
          duration: 0.8,
          scrollTrigger: {
            trigger: ".two-boxes__box--right",
            start: "top 90%",
            end: "bottom",
          },
        });
      }
      return;
    },
    twoBoxesRightOut() {
      if (
        [...document.getElementsByClassName("two-boxes__box--right")].length > 0
      ) {
        this.$gsap.to(".two-boxes__box--right", {
          opacity: 0,
          x: 150,
        });
      }
      return;
    },
    trianglesTopIn() {
      if (
        [...document.getElementsByClassName("two-boxes__triangles-item-top")]
          .length > 0
      ) {
        this.$gsap.from(".two-boxes__triangles-item-top", {
          opacity: 0,
          x: 50,
          scrollTrigger: {
            trigger: ".two-boxes",
            start: "top 80%",
            end: "bottom -40%",
            scrub: true,
          },
        });
      }
      return;
    },
    trianglesTopOut() {
      if (
        [...document.getElementsByClassName("two-boxes__triangles-item-top")]
          .length > 0
      ) {
        this.$gsap.to(".two-boxes__triangles-item-top", {
          opacity: 0,
          x: 75,
        });
      }
      return;
    },
    trianglesBottomIn() {
      if (
        [...document.getElementsByClassName("two-boxes__triangles-item-bottom")]
          .length > 0
      ) {
        this.$gsap.from(".two-boxes__triangles-item-bottom", {
          opacity: 0,
          x: -75,
          scrollTrigger: {
            trigger: ".two-boxes",
            start: "top 50%",
            end: "bottom -25%",
            scrub: true,
          },
        });
      }
      return;
    },
    trianglesBottomOut() {
      if (
        [...document.getElementsByClassName("two-boxes__triangles-item-bottom")]
          .length > 0
      ) {
        this.$gsap.to(".two-boxes__triangles-item-bottom", {
          opacity: 0,
          x: -50,
        });
      }
      return;
    },
  },
  mounted() {
    this.twoBoxesLeftIn();
    this.twoBoxesRightIn();
    this.trianglesTopIn();
    this.trianglesBottomIn();
  },
  beforeDestroy() {
    this.twoBoxesLeftOut();
    this.twoBoxesRightOut();
    this.trianglesTopOut();
    this.trianglesBottomOut();
  },
};

//
//
//
//
//
//
//
//

import HeroSectionDefault from "../components/HeroSectionDefault.vue";
import TwoBoxesSection from "../components/TwoBoxesSection.vue";
import ContactSection from "../components/ContactSection.vue";
import { ScrollTrigger } from "gsap/ScrollTrigger";
export default {
  components: { TwoBoxesSection, HeroSectionDefault, ContactSection },
  props: ["error"],
  data() {
    return {
      block_header: {
        id: 1,
        hidden: false,
        links_color: "red",
        background_color: "red",
        item: "dots",
        headline_top: "Seite nicht gefunden",
        headline_bottom: null,
        body: "Die Seite, nach der Sie suchen, existiert nicht. \nGerne unterstützen wir Sie bei dem Livestreaming Ihrer Veranstaltung und/oder Konfiguration Ihrer eigenen virtuellen Event-Plattform.",
        link_button: null,
        mailto: null,
        background_item_color: "both",
        image_computer: {
          data: null,
        },
      },
      block_boxes: {
        hidden: false,
        single_boxes: [
          {
            headline: "Virtuelle Event-Plattform",
            body: "Ein Event-Management-System für erfolgreiche digitale Veranstaltungen. Planen und produzieren Sie alle Events (Webcast, Online-Fortbildung, Konferenz, Workshop) an einem virtuellen Veranstaltungsort.",
            link_button: {
              page: {
                data: {
                  attributes: {
                    slug: "event-plattform",
                  },
                },
              },
              label: "Event-Plattform-Features",
            },
          },
          {
            headline: "Professionelles Event-Livestreaming",
            body: "Full-Service-Leistungsportfolio: von der Beratung bis zur technischen Umsetzung Ihres digitalen Events. Professionelles Livestreaming mit Kamerateams vor Ort, im Greenscreen-Studio oder komplett virtuell.",
            link_button: {
              page: {
                data: {
                  attributes: {
                    slug: "event-livestreaming",
                  },
                },
              },
              label: "Livestreaming-Möglichkeiten",
            },
          },
        ],
      },
      block_form: {
        headline_top: "Suchen Sie einen Livestreaming-Anbieter?",
        headline_bottom_dynamic: [
          { text: "Sprechen Sie uns an" },
          { text: "Wir beraten Sie gern" },
        ],
        headline_bottom_color: "green",
        form_type: "contact",
        header_images: "manager",
      },
    };
  },
  mounted() {
    ScrollTrigger.refresh();
  },
};

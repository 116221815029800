//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    sticky: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      url: process.env.api_url,
      expanded: false,
      resizeObserver: null,
      menu: [
        { title: "Start", section: "#hero" },
        { title: "Use Cases", section: "#use-cases" },
        { title: "Features", section: "#features" },
        { title: "FAQ", section: "#faq" },
      ],
    };
  },
  mounted() {
    this.resizeObserver = new ResizeObserver(() => {
      if (this.expanded) return;

      document.documentElement.style.setProperty(
        "--navbar-height",
        this.$el.clientHeight + "px"
      );
    });
    this.resizeObserver.observe(this.$el);
  },
  destroyed() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  },
};

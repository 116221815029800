//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SingleCareerBox from "./SingleCareerBox.vue";
export default {
  name: "CareerSection",
  components: { SingleCareerBox },
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  methods: {},
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ImageComponent from "./ImageComponent.vue";
import LinkButton from "./LinkButton.vue";
import VideoComponent from "./VideoComponent.vue";
import { ScrollTrigger } from "gsap/ScrollTrigger";
export default {
  name: "BreakBlockSection",
  components: { LinkButton, ImageComponent, VideoComponent },
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      acceptedYoutubePolicy: this.initAcceptedYoutubePolicyIsChecked(),
      showYoutubeVideos: this.$store.state.acceptedYoutubePolicy,
    };
  },
  watch: {
    "$store.state.acceptedYoutubePolicy": function () {
      this.acceptedYoutubePolicy = this.$store.state.acceptedYoutubePolicy;
      this.showYoutubeVideos = this.$store.state.acceptedYoutubePolicy;
    },
  },
  methods: {
    initAcceptedYoutubePolicyIsChecked: function () {
      if (process.client) {
        const stored = localStorage.getItem("acceptedYoutubePolicy");
        if (stored != "true") {
          // console.log("Nothing stored; default to `false`");
          this.$store.commit("acceptedYoutubePolicy", false);
          this.showYoutubeVideos = false;
          return false;
        } else {
          // console.log("Using stored value " + stored);
          this.$store.commit("acceptedYoutubePolicy", true);
          this.showYoutubeVideos = true;
          return true;
        }
      }
      return false; // Fallback value for SSR
    },
    displayYoutubeVideo(e) {
      this.$store.commit("acceptedYoutubePolicy", e);
      this.showYoutubeVideos = true;
    },
    breakBlockHeadlines() {
      if (this.block.video.data || this.block.poster_video.data) {
        this.$gsap.fromTo(
          `.break-block__headline--left-${this.block.id}`,
          {
            x: 0,
            opacity: 0,
          },
          {
            x: "-50%",
            opacity: 1,
            scrollTrigger: {
              trigger: `.break-block-${this.block.id}`,
              end: "bottom",
              scrub: true,
            },
          }
        );
        this.$gsap.fromTo(
          `.break-block__headline--right-${this.block.id}`,
          {
            x: 0,
            opacity: 0,
          },
          {
            x: "50%",
            opacity: 1,
            scrollTrigger: {
              trigger: `.break-block-${this.block.id}`,
              end: "bottom",
              scrub: true,
            },
          }
        );
      } else {
        this.$gsap.fromTo(
          `.break-block__headline--left-${this.block.id}`,
          {
            x: 0,
            opacity: 0,
          },
          {
            x: "50%",
            opacity: 1,
            scrollTrigger: {
              trigger: `.break-block-${this.block.id}`,
              end: "bottom",
              scrub: true,
            },
          }
        );
        this.$gsap.fromTo(
          `.break-block__headline--right-${this.block.id}`,
          {
            x: 0,
            opacity: 0,
          },
          {
            x: "-50%",
            opacity: 1,
            scrollTrigger: {
              trigger: `.break-block-${this.block.id}`,
              end: "bottom",
              scrub: true,
            },
          }
        );
      }
    },
  },
  mounted() {
    this.breakBlockHeadlines();
    ScrollTrigger.refresh();
  },
};

import { render, staticRenderFns } from "./ReferencesSection.vue?vue&type=template&id=c5ce8a0e&scoped=true&"
import script from "./ReferencesSection.vue?vue&type=script&lang=js&"
export * from "./ReferencesSection.vue?vue&type=script&lang=js&"
import style0 from "./ReferencesSection.vue?vue&type=style&index=0&id=c5ce8a0e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c5ce8a0e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ImageComponent: require('/app/components/ImageComponent.vue').default})

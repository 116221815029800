//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ScrollTrigger } from "gsap/ScrollTrigger";
export default {
  name: "QuotesSection",
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      sliderSettings: {
        speed: 900,
        loop: true,
        autoplay: {
          delay: this.block.delay_seconds
            ? this.block.delay_seconds * 1000
            : 10000,
          disableOnInteraction: false,
        },
      },
      sliderSettingsOneSlide: {
        init: false,
      },
    };
  },
  methods: {
    quotesIn() {
      if ([...document.getElementsByClassName("quotes")].length > 0) {
        this.$gsap.from(`quotes-${this.block.id}`, {
          opacity: 0,
          y: 50,
          scrollTrigger: {
            trigger: ".quotes",
            start: "top 90%",
            end: "bottom",
            scrub: false,
          },
        });
      }
    },
    quotesOut() {
      if ([...document.getElementsByClassName("quotes")].length > 0) {
        this.$gsap.to(`quotes-${this.block.id}`, {
          opacity: 0,
          y: 50,
        });
      }
    },
  },
  mounted() {
    this.quotesIn();
    ScrollTrigger.refresh();
  },
  beforeDestroy() {
    this.quotesOut();
  },
};

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.imageSize === 'large')?_c('nuxt-img',{class:_vm.defaultClass,attrs:{"loading":_vm.lazy ? 'lazy' : 'normal',"provider":"strapi","src":_vm.image.attributes.formats && _vm.image.attributes.url
      ? _vm.image.attributes.url
      : _vm.image.attributes.formats.large.url,"alt":_vm.image.attributes.alternativeText,"width":"100","height":"100","sizes":"sm:100vw md:50vw lg:400px"}}):(_vm.imageSize === 'medium')?_c('nuxt-img',{class:_vm.defaultClass,attrs:{"loading":_vm.lazy ? 'lazy' : 'normal',"provider":"strapi","src":_vm.image.attributes.formats && _vm.image.attributes.formats.medium
      ? _vm.image.attributes.formats.medium.url
      : _vm.image.attributes.url,"alt":_vm.image.attributes.alternativeText,"width":"100","height":"100","sizes":"sm:100vw lg:600px"}}):(_vm.imageSize === 'thumbnail')?_c('nuxt-img',{class:_vm.defaultClass,attrs:{"loading":_vm.lazy ? 'lazy' : 'normal',"provider":"strapi","src":_vm.image.attributes.formats && _vm.image.attributes.formats.thumbnail
      ? _vm.image.attributes.formats.thumbnail.url
      : _vm.image.attributes.url,"alt":_vm.image.attributes.alternativeText,"width":"1","height":"1"}}):_c('nuxt-img',{class:_vm.defaultClass,attrs:{"loading":_vm.lazy ? 'lazy' : 'normal',"provider":"strapi","src":_vm.image.attributes.formats && _vm.image.attributes.formats.small
      ? _vm.image.attributes.formats.small.url
      : _vm.image.attributes.url,"alt":_vm.image.attributes.alternativeText,"width":"1","height":"1"}})}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loading)?_c('div',[_c('spinner')],1):_c('div',[(_vm.error)?_c('div',{staticClass:"d-flex flex-column align-items-center justify-content-center py-5"},[_c('p',{staticClass:"text-center"},[_vm._v("\n      Sorry, an error has occurred, please try again later.\n    ")])]):_vm._e(),_vm._v(" "),(_vm.success)?_c('div',{staticClass:"d-flex flex-column align-items-center justify-content-center py-5"},[_c('p',{staticClass:"text-center text-success"},[_vm._v("\n      Thank you! Your message has been successfully sent.\n    ")])]):_c('div',{attrs:{"id":"contact-form"}},[_c('FormulateForm',{ref:"contact-form",on:{"submit":_vm.submitForm}},[_c('div',{staticClass:"layout"},[_c('FormulateInput',{attrs:{"type":"text","placeholder":_vm.name,"validation":"required|max:50","maxlength":50,"validation-messages":{
            required: '* The name is required',
            max: '* Der Name darf maximal 50 Zeichen lang sein',
          }},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_vm._v(" "),_c('div',{staticClass:"layout phone"},[_c('FormulateInput',{attrs:{"type":"email","maxlength":250,"placeholder":_vm.email,"validation":"required|email","validation-messages":{
              required: '* E-mail is required',
              email: '* Unfortunately this is not a valid e-mail address',
            }},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.email"}}),_vm._v(" "),_c('FormulateInput',{attrs:{"type":"text","maxlength":250,"name":"phone","placeholder":_vm.phone}})],1),_vm._v(" "),_c('FormulateInput',{attrs:{"type":"textarea","maxlength":750,"placeholder":_vm.message,"validation":"required","validation-messages":{
            required: '* A message is required',
          }},model:{value:(_vm.form.message),callback:function ($$v) {_vm.$set(_vm.form, "message", $$v)},expression:"form.message"}})],1),_vm._v(" "),_c('div',{staticClass:"layout checkboxes"},[_c('FormulateInput',{attrs:{"type":"checkbox","label":_vm.privacyText,"validation":"accepted","validation-messages":{
            accepted: '* Please accept the privacy policy',
          }},model:{value:(_vm.form.privacy),callback:function ($$v) {_vm.$set(_vm.form, "privacy", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.privacy"}})],1),_vm._v(" "),_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.hp),expression:"form.hp"}],staticClass:"out-of-display",attrs:{"type":"checkbox","placeholder":"check this field for security reasons","name":"checkbox","tabindex":"-1","autocomplete":"false"},domProps:{"checked":Array.isArray(_vm.form.hp)?_vm._i(_vm.form.hp,null)>-1:(_vm.form.hp)},on:{"change":function($event){var $$a=_vm.form.hp,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.form, "hp", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.form, "hp", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.form, "hp", $$c)}}}}),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.hp2),expression:"form.hp2"}],staticClass:"out-of-display",attrs:{"type":"text","placeholder":"fill this field with your firstname","name":"firstname","tabindex":"-1","autocomplete":"false"},domProps:{"value":(_vm.form.hp2)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "hp2", $event.target.value)}}}),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.hp3),expression:"form.hp3"}],staticClass:"out-of-display",attrs:{"type":"email","placeholder":"type your email here","name":"email","tabindex":"-1","autocomplete":"false"},domProps:{"value":(_vm.form.hp3)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "hp3", $event.target.value)}}})]),_vm._v(" "),_c('FormulateInput',{staticClass:"contact-btn",attrs:{"type":"submit","label":_vm.submit}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ArrowBackground from './ArrowBackground.vue';
import PlayArrow from './PlayArrow.vue';
export default {
  components: {
    ArrowBackground,
    PlayArrow
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ImageComponent from "./ImageComponent.vue";
export default {
  name: "VideoComponent",
  components: { ImageComponent },
  data() {
    return {
      url: process.env.api_url,
      showPosterStart: true,
      showPosterEnd: false,
    };
  },
  props: {
    embed_video: {
      type: String,
    },
    video: {
      type: Object,
    },
    poster_video: {
      type: Object,
      required: false,
    },
    poster_video_end: {
      type: Object,
      required: false,
    },
    imageSize: {
      type: String,
      required: false,
    },
    showPlayButton: {
      type: Boolean,
      default: true,
      required: false,
    },
    play_button_color: {
      type: String,
      required: false,
    },
    reference: {
      type: String,
    },
    hasAutoplay: {
      type: Boolean,
      default: false,
    },
    lazyLoad: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    playVideo() {
      this.showPosterStart = false;
      this.showPosterEnd = false;
      this.$refs[this.reference ? this.reference : "videoElement"].play();
    },
    updatePaused() {
      if (this.$refs[this.reference].playing) {
        this.$refs[this.reference].pause();
      } else {
        this.$refs[this.reference].play();
      }
    },
    handleVideoEnded() {
      // Video has ended, show the poster picture again
      if (this.$props.poster_video_end && this.$props.poster_video_end.data) {
        this.showPosterEnd = true;
      } else {
        this.showPosterStart = true;
      }
    },
  },
};

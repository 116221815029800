//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LinkButton from "./LinkButton.vue";
import MailtoButton from "./MailtoButton.vue";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import VideoComponent from "./VideoComponent.vue";
export default {
  name: "HeroSectionVideo",
  components: { LinkButton, MailtoButton, VideoComponent },
  transition: "default",
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    ScrollTrigger.refresh();
  },
  methods: {
    autoplayVideo() {
      this.$refs.heroSectionVideoComponent.playVideo();
    },
    formatTextWithLinebreaks(text) {
      return text.replace(/<\/br>/g, "<br>");
    },
  },
  computed: {
    isHomePage() {
      const path = this.$root._route.fullPath;
      return path === "/" || path.includes("/preview/home-test");
    },
  },
  mounted() {
    this.autoplayVideo();
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LinkButton from "./LinkButton.vue";
import VideoComponent from "./VideoComponent.vue";
export default {
  name: "NewsSlider",
  components: { LinkButton, VideoComponent },
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      acceptedYoutubePolicy: this.initAcceptedYoutubePolicyIsChecked(),
      showYoutubeVideos: this.$store.state.acceptedYoutubePolicy,
      sliderSettings: {
        loop: false,
        effect: "fade",
        // autoplay: {
        //   delay: 5000,
        //   disableOnInteraction: false,
        // },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  watch: {
    "$store.state.acceptedYoutubePolicy": function () {
      this.acceptedYoutubePolicy = this.$store.state.acceptedYoutubePolicy;
      this.showYoutubeVideos = this.$store.state.acceptedYoutubePolicy;
    },
  },
  methods: {
    initAcceptedYoutubePolicyIsChecked: function () {
      if (process.client) {
        const stored = localStorage.getItem("acceptedYoutubePolicy");
        if (stored != "true") {
          // console.log("Nothing stored; default to `false`");
          this.$store.commit("acceptedYoutubePolicy", false);
          this.showYoutubeVideos = false;
          return false;
        } else {
          // console.log("Using stored value " + stored);
          this.$store.commit("acceptedYoutubePolicy", true);
          this.showYoutubeVideos = true;
          return true;
        }
      }
      return false; // Fallback value for SSR
    },
    displayYoutubeVideo(e) {
      this.$store.commit("acceptedYoutubePolicy", e);
      this.showYoutubeVideos = true;
    },
    onSlideChange() {
      const videos = [];
      [...document.querySelectorAll("video")].map((video) =>
        videos.push(video)
      );
      [...document.querySelectorAll("iframe")].map((iframe) =>
        videos.push(iframe)
      );

      videos.map((video) => {
        if (video.matches(".video-embed-item")) {
          var src = video.src;
          video.src = src;
        } else {
          video.pause();
        }
      });
    },
  },
};

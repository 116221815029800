var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"main-header text-light",class:_vm.isDesktop ? 'desktop-size' : 'mobile-size'},[_c('div',{staticClass:"container px-0"},[_c('b-navbar',{staticClass:"navbar-horizontal",attrs:{"toggleable":"xl","variant":"default"}},[_c('b-navbar-brand',{attrs:{"to":'/'}},[_c('img',{staticClass:"main-logo",attrs:{"src":require("assets/img/logo-techcast.svg"),"alt":"Techcast Logo","title":"","width":"1","height":"1"}})]),_vm._v(" "),(_vm.menu && _vm.menu.data.attributes.items.data)?[_c('b-navbar-toggle',{staticClass:"d-xl-none",attrs:{"target":"nav-collapse"}},[_c('svg',{staticClass:"burger-svg",attrs:{"width":"100","height":"100","viewBox":"0 0 100 100"}},[_c('path',{staticClass:"line line1",attrs:{"d":"M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"}}),_vm._v(" "),_c('path',{staticClass:"line line2",attrs:{"d":"M 20,50 H 80"}}),_vm._v(" "),_c('path',{staticClass:"line line3",attrs:{"d":"M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"}})])]),_vm._v(" "),_c('b-collapse',{attrs:{"is-nav":"","id":"nav-collapse"}},[_c('ul',{staticClass:"d-flex flex-column flex-xl-row ml-auto py-xl-0 navbar-nav"},[_vm._l((_vm.menu.data.attributes.items.data),function(menuItem){return [(
                  !menuItem.attributes.hidden && menuItem.attributes.isButton
                )?_c('li',{staticClass:"nav-item button-wrapper"},[(
                    !menuItem.attributes.hidden &&
                    menuItem.attributes.page.data
                  )?_c('nuxt-link',{staticClass:"button",attrs:{"role":"presentation","to":'/' + menuItem.attributes.page.data.attributes.slug,"target":menuItem.attributes.target === '_blank' && '_blank'}},[_c('span',[_vm._v("\n                    "+_vm._s(menuItem.attributes.title)+"\n                  ")])]):_vm._e()],1):_vm._e(),_vm._v(" "),(
                  !menuItem.attributes.hidden && !menuItem.attributes.isButton
                )?_c('li',{key:menuItem.id,staticClass:"nav-item dropdown"},[(
                    !menuItem.attributes.hidden &&
                    menuItem.attributes.page.data
                  )?_c('nuxt-link',{staticClass:"nav-link text-center px-0",class:menuItem.attributes.children.data &&
                    menuItem.attributes.children.data.length > 0
                      ? 'd-flex align-items-start'
                      : '',attrs:{"role":"presentation","to":'/' + menuItem.attributes.page.data.attributes.slug,"target":menuItem.attributes.target === '_blank' && '_blank'}},[_vm._v("\n                  "+_vm._s(menuItem.attributes.title)+"\n                  "),(
                      menuItem.attributes.children.data &&
                      menuItem.attributes.children.data.length > 0
                    )?_c('font-awesome-icon',{staticClass:"fa-icon sort-down ml-2 d-none d-xl-block",staticStyle:{"font-size":"1rem"},attrs:{"icon":{ prefix: 'fa', iconName: 'sort-down' }}}):_vm._e()],1):_c('span',{staticClass:"nav-link text-center px-0",class:menuItem.attributes.children.data &&
                    menuItem.attributes.children.data.length > 0
                      ? 'd-flex align-items-start'
                      : '',attrs:{"role":"presentation"}},[_vm._v("\n                  "+_vm._s(menuItem.attributes.title)+"\n                  "),(
                      menuItem.attributes.children.data &&
                      menuItem.attributes.children.data.length > 0
                    )?_c('font-awesome-icon',{staticClass:"fa-icon sort-down ml-2 d-none d-xl-block",staticStyle:{"font-size":"1rem"},attrs:{"icon":{ prefix: 'fa', iconName: 'sort-down' }}}):_vm._e()],1),_vm._v(" "),(
                    menuItem.attributes.children.data &&
                    menuItem.attributes.children.data.length > 0
                  )?_c('ul',{staticClass:"dropdown-menu dropdown-menu-right",attrs:{"aria-labelledby":"navbarDropdown"}},[_vm._l((menuItem.attributes.children.data),function(menuItemChild){return [(!menuItemChild.attributes.hidden)?_c('li',{key:menuItemChild.id,staticClass:"nav-item text-left px-0"},[(
                          !menuItemChild.attributes.hidden &&
                          menuItemChild.attributes.page.data
                        )?_c('nuxt-link',{staticClass:"nav-link text-left px-xl-0",attrs:{"role":"presentation","to":'/' +
                          menuItemChild.attributes.page.data.attributes.slug,"target":menuItemChild.attributes.target === '_blank' &&
                          '_blank'}},[_vm._v("\n                        "+_vm._s(menuItemChild.attributes.title)+"\n                      ")]):_vm._e()],1):_vm._e()]})],2):_vm._e()],1):_vm._e()]})],2),_vm._v(" "),_c('span',{staticClass:"underbar"})])]:_vm._e()],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
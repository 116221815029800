//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ExternLink from "./ExternLink.vue";
import { ScrollTrigger } from "gsap/ScrollTrigger";
export default {
  name: "ClosedAccordionSection",
  components: ExternLink,
  props: {
    block: {
      type: Object,
      required: true,
    },
    sitePresse: {
      type: Boolean,
    },
  },
  data() {
    return {
      posts: this.block.closed_accordion_items,
      searchKey: "",
      currentPage: 1,
      itemsPerPage: this.block.items_per_page ? this.block.items_per_page : 5,
      resultCount: 0,
    };
  },
  computed: {
    totalPages: function () {
      return Math.ceil(this.resultCount / this.itemsPerPage);
    },

    paginate: function () {
      if (!this.posts || this.posts.length != this.posts.length) {
        return;
      }
      this.resultCount = this.posts.length;
      if (this.currentPage >= this.totalPages) {
        this.currentPage = this.totalPages;
      }
      var index = this.currentPage * this.itemsPerPage - this.itemsPerPage;
      return this.posts.slice(index, index + this.itemsPerPage);
    },
  },
  methods: {
    setPage: function (pageNumber) {
      this.currentPage = pageNumber;
      this.scrollToTopClosedAccordion();
    },
    scrollToTopClosedAccordion() {
      setTimeout(() => {
        this.$refs.closedAccordion.scrollIntoView({ behavior: "smooth" });
      }, 500);
    },
  },
  mounted() {
    ScrollTrigger.refresh();
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import GradientLink from "~/components/english-site/GradientLink.vue";
export default {
  components: {
    GradientLink,
  },
  props: {
    cta: {
      type: String,
      default: "",
    },
    headline: {
      type: String,
      default: "",
    },
    product: {
      type: String,
      default: "",
    },
    content: {
      type: String,
      default: "",
    },
  },
};

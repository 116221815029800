// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/english-site/triangle-background-patch.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".techcast-english .arrow-background[data-v-785eafb7]{opacity:.6;background:linear-gradient(90deg,#3763bd 20%,#00f74e 50%,#3763bd 80%);background-size:400% 400%;-webkit-mask-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");mask-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");-webkit-mask-repeat:repeat;mask-repeat:repeat;position:relative;-webkit-animation:Animation-data-v-785eafb7 5s infinite;animation:Animation-data-v-785eafb7 5s infinite}.techcast-english .arrow-background[data-v-785eafb7]:before{content:\"\";position:absolute;top:0;right:0;bottom:0;left:0;background:linear-gradient(180deg,#fff,transparent 20%)}.techcast-english .arrow-background[data-v-785eafb7]:after{content:\"\";position:absolute;top:0;right:0;bottom:0;left:0;background:linear-gradient(0deg,#fff,transparent 20%)}@-webkit-keyframes Animation-data-v-785eafb7{0%{background-position:100% 50%}to{background-position:0 50%}}@keyframes Animation-data-v-785eafb7{0%{background-position:100% 50%}to{background-position:0 50%}}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "TextSlider",
  props: {
    block: {
      type: Array,
      required: true,
    },
    header_slider_classes: {
      type: String,
    },
    text_font: {
      type: String,
    },
  },
  data() {
    return {
      sliderSettings: {
        loop: true,
        direction: "vertical",
        stretch: 1,
        spaceBetween: 0,
        speed: 900,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
      },
    };
  },
};

//
//
//
//
//
//
//
//
//
//
//

import Navbar from "../components/english-site/Navbar.vue";
import PageFooter from "../components/english-site/PageFooter.vue";
import ScrollTopButton from "../components/ScrollTopButton.vue";

export default {
  components: { ScrollTopButton, PageFooter, Navbar },
  data() {
    return {};
  },
  head() {
    return {
      title: "English Site Techcast",
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        {
          hid: "description",
          name: "description",
          content: "english site description",
        },
        {
          hid: "og:title",
          property: "og:title",
          content: "content title",
        },
        {
          hid: "og:desc",
          property: "og:description",
          content: "content description",
        },
        // twitter card
        {
          hid: "twitter:title",
          name: "twitter:title",
          content: "twitter card title",
        },
        {
          hid: "twitter:description",
          name: "twitter:description",
          content: "twitter card description",
        },
      ],
    };
  },
};

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.block.hidden)?_c('div',{staticClass:"section features position-relative",class:'links-color-' + _vm.block.links_color + ' features-' + _vm.block.id},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[(_vm.block.square_item)?_c('div',{staticClass:"col-lg-3"},[_c('square-item',{attrs:{"background_color":_vm.block.headline_color,"block":_vm.block}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"col-12 position-relative",class:_vm.block.square_item && 'col-lg-9'},[_c('div',{staticClass:"mb-7"},[(_vm.block.headline)?_c('h2',{class:'with-text-gradient gradient-' + _vm.block.headline_color},[_vm._v("\n            "+_vm._s(_vm.block.headline)+"\n          ")]):_vm._e(),_vm._v(" "),(_vm.block.body)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.$md.render(_vm.block.body))}}):_vm._e()]),_vm._v(" "),_c('transition-group',{staticClass:"row features__box--container",attrs:{"appear":"","tag":"div"},on:{"before-enter":_vm.beforeEnter,"enter":_vm.enter}},[_vm._l((_vm.block.single_features),function(feature,index){return _c('div',{key:feature.id,class:_vm.block.single_features.length <= 4 && _vm.block.square_item
                ? 'col-12 col-md-6 features__box mb-2 mb-xl-3 py-3'
                : _vm.block.single_features.length <= 4
                ? 'col-12 col-md-6 col-xl-3 features__box mb-2 mb-xl-3 py-3'
                : _vm.block.single_features.length > 4 && _vm.block.square_item
                ? 'col-12 col-md-6 features__box mb-2 mb-xl-3 py-3'
                : 'col-12 col-md-6 col-xl-4 features__box mb-2 mb-xl-3 py-3',attrs:{"data-index":index}},[_c('single-feature',{attrs:{"block":_vm.block,"feature":feature}})],1)}),_vm._v(" "),(_vm.block.slider)?_c('div',{key:'feature-slider-' + _vm.block.slider.id,staticClass:"\n              gradient-black\n              py-5\n              px-md-2 px-xl-4\n              features__box--slider--wrapper\n            ",class:_vm.block.single_features.length <= 4 && _vm.block.square_item
                ? 'col-12 col-md-6 features__box--slider mb-2 mb-xl-3'
                : _vm.block.single_features.length <= 4
                ? 'col-12 col-md-6 col-xl-3 features__box--slider mb-2 mb-xl-3'
                : _vm.block.single_features.length > 4 && _vm.block.square_item
                ? 'col-12 col-md-6 features__box--slider mb-2 mb-xl-3'
                : 'col-12 col-md-6 col-xl-4 features__box--slider mb-2 mb-xl-3'},[(_vm.block.slider.headline)?_c('h3',{staticClass:"w-100 text-center",class:'with-text-gradient--' + _vm.block.headline_color},[_vm._v("\n              "+_vm._s(_vm.block.slider.headline)+"\n            ")]):_vm._e(),_vm._v(" "),(_vm.block.slider)?_c('features-slider',{attrs:{"slides":_vm.block.slider.slides}}):_vm._e()],1):_vm._e()],2),_vm._v(" "),(_vm.block.link_button && _vm.block.link_button.page.data)?_c('link-button',{staticClass:"mt-7 mb-4",attrs:{"link":_vm.block.link_button.page.data.attributes.slug,"label":_vm.block.link_button.label}}):(_vm.block.link_button && _vm.block.link_button.blog_post.data)?_c('link-button',{staticClass:"mt-7 mb-4",attrs:{"link":'wissenswertes/' + _vm.block.link_button.blog_post.data.attributes.slug,"label":_vm.block.link_button.label}}):(_vm.block.link_button && _vm.block.link_button.case_study.data)?_c('link-button',{staticClass:"mt-7 mb-4",attrs:{"link":'case-studies/' +_vm.block.link_button.case_study.data.attributes.slug,"label":_vm.block.link_button.label}}):(_vm.block.link_button && _vm.block.link_button.presse_post.data)?_c('link-button',{staticClass:"mt-7 mb-4",attrs:{"link":'presse/' + _vm.block.link_button.presse_post.data.attributes.slug,"label":_vm.block.link_button.label}}):_vm._e()],1)])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
//
//
//
//
//
//
//
//
//
//
//

export default {
  inheritAttrs: false,
  data() {
    return {
      height: "auto",
    };
  },
  mounted() {
    const nextItem = this.$el.querySelector(".carousel-item.active");
    if (!(nextItem instanceof HTMLElement)) return;

    this.height = `${nextItem.clientHeight}px`;
  },
  methods: {
    async onSlide() {
      await this.$nextTick();
      const nextItem = this.$el.querySelector(
        ".carousel-item-next, .carousel-item-prev"
      );
      if (!(nextItem instanceof HTMLElement)) return;

      this.height = `${nextItem.clientHeight}px`;
    },
  },
};

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/english-site/chevron.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".techcast-english .carousel-item{position:relative;padding:2.5rem 2.875rem 2rem}.techcast-english .carousel-control-prev{left:0}.techcast-english .carousel-control-next{right:0}.techcast-english .carousel-control-next,.techcast-english .carousel-control-prev{width:auto;padding:0 1rem;top:50%;position:absolute}.techcast-english .carousel-control-next:hover,.techcast-english .carousel-control-prev:hover{opacity:1}.techcast-english .carousel-control-next-icon,.techcast-english .carousel-control-prev-icon{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");height:1.5rem}.techcast-english .carousel-control-prev-icon{transform:scaleX(-1)}.techcast-english ol.carousel-indicators{margin-bottom:1.5rem;grid-gap:1rem;gap:1rem}.techcast-english .carousel-indicators li{height:0;width:34px;border:3px solid #fff;border-radius:100vmax}@media(min-width:815px){.techcast-english .carousel-item{padding:2rem 7rem}.techcast-english .carousel-control-next,.techcast-english .carousel-control-prev{padding:0 3rem}.techcast-english .carousel-control-next-icon,.techcast-english .carousel-control-prev-icon{height:2rem}.techcast-english ol.carousel-indicators{margin-bottom:2rem}.techcast-english .carousel-indicators li{width:48px}}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MenuItem from "./MenuItem.vue";
import NewsletterInput from "./NewsletterInput.vue";
import TheFooterLegal from "./TheFooterLegal.vue";
import TheFooterSocial from "./TheFooterSocial.vue";
import qs from 'qs';
export default {
  name: "TheFooter",
  components: { TheFooterLegal, MenuItem, TheFooterSocial, NewsletterInput },
  data() {
    return {
      currentYear: new Date().getFullYear(),
      menu: [],
      menuSocial: [],
      menuLegal: [],
      year: "",
    };
  },
  async fetch() {
    const params = {
      nested: true,
      populate: {
        items: {
          populate: {
            page: true,
          },
        },
      },
    };

    const query = qs.stringify(params, { addQueryPrefix: true });

    this.menu = await fetch(`${process.env.api_url}/api/menus/1${query}`)
      .then((res) => res.json())
      .then((res2) => res2.data.attributes.items.data.filter((item) => item.attributes.hidden === false));
    this.menuSocial = await fetch(
      `${process.env.api_url}/api/menus/3${query}`
    ).then((res) => res.json());
    this.menuLegal = await fetch(
      `${process.env.api_url}/api/menus/4${query}`
    ).then((res) => res.json());
  },
  mounted() {
    this.year = new Date().getFullYear();
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "DownloadButton",
  props: {
    link: {
      type: String,
    },
    label: {
      type: String,
    },
    fileName: {
      type: String,
    },
    classes: {
      type: String,
    },
  },
  data() {
    return {
      url: process.env.api_url,
      mutableLink: this.link,
    };
  },
  mounted() {
    this.link === "home" ? (this.mutableLink = "") : false;
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "ScrollTopButton",
  data() {
    return {
      scTimer: 0,
      scY: 0,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (this.scTimer) return;
      this.scTimer = setTimeout(() => {
        this.scY = window.scrollY;
        clearTimeout(this.scTimer);
        this.scTimer = 0;
      }, 100);
    },
    toTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    showScrollButton() {
      if ([...document.getElementsByClassName("scrolltop-button")].length) {
        this.$gsap.to(".scrolltop-button", {
          opacity: 1,
          ease: "none",
          scrollTrigger: {
            trigger: ".page",
            start: "20% top",
            end: "+=100",
            scrub: true,
          },
        });
      }
    },
  },
  mounted() {
    this.showScrollButton();
  },
};

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.block.intern_link)?_c('div',{staticClass:"mb-5 px-4"},[(_vm.block.intern_link && _vm.block.intern_link.page.data)?_c('link-button',{staticClass:"my-4 mx-auto",class:_vm.block.link_color !== 'black'
        ? 'border-0 gradient-' + _vm.block.link_color
        : 'gradient-' + _vm.block.link_color,attrs:{"link":_vm.block.intern_link.page.data.attributes.slug,"label":_vm.block.intern_link.label}}):(_vm.block.intern_link && _vm.block.intern_link.blog_post.data)?_c('link-button',{staticClass:"my-4 mx-auto",class:_vm.block.link_color !== 'black'
        ? 'border-0 gradient-' + _vm.block.link_color
        : 'gradient-' + _vm.block.link_color,attrs:{"link":'wissenswertes/' + _vm.block.intern_link.blog_post.data.attributes.slug,"label":_vm.block.intern_link.label}}):(_vm.block.intern_link && _vm.block.intern_link.case_study.data)?_c('link-button',{staticClass:"my-4 mx-auto",class:_vm.block.link_color !== 'black'
        ? 'border-0 gradient-' + _vm.block.link_color
        : 'gradient-' + _vm.block.link_color,attrs:{"link":'case-studies/' + _vm.block.intern_link.case_study.data.attributes.slug,"label":_vm.block.intern_link.label}}):(_vm.block.intern_link && _vm.block.intern_link.presse_post.data)?_c('link-button',{staticClass:"my-4 mx-auto",class:_vm.block.link_color !== 'black'
        ? 'border-0 gradient-' + _vm.block.link_color
        : 'gradient-' + _vm.block.link_color,attrs:{"link":'presse/' + _vm.block.intern_link.presse_post.data.attributes.slug,"label":_vm.block.intern_link.label}}):_vm._e()],1):_c('div',{staticClass:"mb-5 px-4"},[(_vm.block.extern_link)?_c('a',{staticClass:"button my-4 mx-auto",class:_vm.block.link_color !== 'black'
        ? 'border-0 gradient-' + _vm.block.link_color
        : 'gradient-' + _vm.block.link_color,attrs:{"href":_vm.block.extern_link.url,"target":"_blank"}},[_c('font-awesome-icon',{staticClass:"fa-icon right-long mr-3",staticStyle:{"font-size":"1.6rem"},attrs:{"icon":{ prefix: 'fa', iconName: 'right-long' }}}),_vm._v(_vm._s(_vm.block.extern_link.label)+"\n  ")],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    reference: {
      type: String,
      default: "",
    },
    video: {
      type: Object,
      default: () => ({}),
    },
    hasToPreload: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      url: process.env.api_url,
      showPlayButton: false,
    };
  },
  methods: {
    playVideo() {
      this.showPlayButton = false;
      const videoElement = this.$refs[this.reference];
      videoElement.play();
    },
    handleVideoEnded() {
      this.showPlayButton = true;
    },
    preloadVideo() {
      const videoElement = this.$refs[this.reference];
      if (videoElement && this.hasToPreload) {
        videoElement.load(); // Explicitly preload the video
      }
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MailtoButton from "./MailtoButton.vue";
import PhoneButton from "./PhoneButton.vue";
export default {
  name: "TeamMember",
  components: { PhoneButton, MailtoButton },
  props: {
    button_color: {
      type: String,
    },
    member: {
      type: Object,
      required: true,
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ImageComponent from "./ImageComponent.vue";
import LinkButton from "./LinkButton.vue";
import TextSlider from "./TextSlider.vue";
import { ScrollTrigger } from "gsap/ScrollTrigger";
export default {
  name: "HeroSectionHomepage",
  components: { LinkButton, TextSlider, ImageComponent },
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  methods: {
    heroBoxLeftIn() {
      if ([...document.getElementsByClassName("hero__box-left")].length > 0) {
        this.$gsap.from(".hero__box-left", {
          opacity: 0,
          x: -50,
          duration: 1,
        });
      }
    },
    heroBoxLeftOut() {
      if ([...document.getElementsByClassName("hero__box-left")].length > 0) {
        this.$gsap.to(".hero__box-left", {
          opacity: 0,
          x: -50,
        });
      }
    },
    heroBoxRightIn() {
      if (
        [...document.getElementsByClassName("hero__box-right__wrapper")]
          .length > 0
      ) {
        this.$gsap.from(".hero__box-right__wrapper", {
          opacity: 0,
          x: 50,
          duration: 1,
        });
      }
    },
    heroBoxRightOut() {
      if (
        [...document.getElementsByClassName("hero__box-right__wrapper")]
          .length > 0
      ) {
        this.$gsap.to(".hero__box-right__wrapper", {
          opacity: 0,
          x: 50,
        });
      }
    },
    heroBodyIn() {
      if ([...document.getElementsByClassName("hero-body")].length) {
        this.$gsap.from(".hero-body", {
          opacity: 0,
          y: 25,
          duration: 0.8,
        });
      }
    },
    heroBodyOut() {
      if ([...document.getElementsByClassName("hero-body")].length) {
        this.$gsap.to(".hero-body", {
          opacity: 0,
          y: 25,
          duration: 0.8,
        });
      }
    },
  },
  mounted() {
    this.heroBoxLeftIn();
    this.heroBoxRightIn();
    // this.heroBodyIn();
    ScrollTrigger.refresh();
  },
  beforeDestroy() {
    this.heroBoxLeftOut();
    this.heroBoxRightOut();
    this.heroBodyOut();
  },
};

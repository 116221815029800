var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loading)?_c('div',[_c('spinner')],1):_c('div',[(_vm.error)?_c('div',{staticClass:"d-flex flex-column align-items-center justify-content-center py-5"},[_c('p',{staticClass:"text-center"},[_vm._v("\n      Entschuldigung, es ist ein Fehler aufgetreten, versuchen Sie es später\n      noch einmal...\n    ")])]):_c('div',{staticClass:"row",attrs:{"id":"contact-form"}},[_c('div',{staticClass:"col-12"},[_c('formulate-form',{ref:"contact-form",staticClass:"d-flex flex-column",on:{"submit":_vm.formSubmit}},[_c('div',{staticClass:"form-item"},[_c('formulate-input',{attrs:{"type":"text","placeholder":"Name*","validation":"required|max:50","input-class":"mb-3 w-100","validation-messages":{
              required: '* Der Name ist erforderlich',
              max: '* Der Name darf maximal 50 Zeichen lang sein',
            }},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_vm._v(" "),_c('div',{staticClass:"row form-item mx-0"},[_c('div',{staticClass:"col-12 px-0 col-md-6 pr-md-1"},[_c('formulate-input',{attrs:{"type":"tel","placeholder":"Telefonnummer","validation":"number","input-class":"mb-3 w-100","validation-messages":{
                number: '* Die Telefonnummer kann nur Zahlen enthalten',
              }},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})],1),_vm._v(" "),_c('div',{staticClass:"col-12 px-0 col-md-6 pl-md-1"},[_c('b-input-group',{staticClass:"mb-3 w-100"},[_c('b-form-input',{attrs:{"id":"date","type":"text","placeholder":"Rückrufwunsch","autocomplete":"off"},model:{value:(_vm.form.date),callback:function ($$v) {_vm.$set(_vm.form, "date", $$v)},expression:"form.date"}}),_vm._v(" "),_c('b-input-group-append',[_c('b-form-datepicker',{attrs:{"date-disabled-fn":_vm.dateDisabled,"today-button":"","button-only":"","right":"","locale":"de","aria-controls":"date","aria-label":"open calendar","title":"open calendar","min":_vm.min},model:{value:(_vm.form.date),callback:function ($$v) {_vm.$set(_vm.form, "date", $$v)},expression:"form.date"}})],1)],1)],1)]),_vm._v(" "),_c('div',{staticClass:"form-item"},[_c('formulate-input',{attrs:{"type":"email","placeholder":"E-Mail*","validation":"required|email","input-class":"mb-3 w-100","validation-messages":{
              required: '* E-Mail ist erforderlich',
              email: '* Leider ist das keine gültige E-Mail-Adresse',
            }},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.email"}})],1),_vm._v(" "),_c('div',{staticClass:"form-item"},[_c('formulate-input',{attrs:{"type":"textarea","placeholder":"Ihre Nachricht*","input-class":"mb-3 w-100","validation":"required","validation-messages":{
              required: '* Eine Nachricht ist erforderlich',
            }},model:{value:(_vm.form.message),callback:function ($$v) {_vm.$set(_vm.form, "message", $$v)},expression:"form.message"}})],1),_vm._v(" "),_c('formulate-input',{attrs:{"type":"checkbox","id":"techcast-newsletter","label":"Ich möchte mit dem Techcast-Newsletter zu Online-Events eingeladen und über Livestreaming & Co. informiert werden. Abmeldung ist jederzeit möglich.","wrapper-class":"d-flex align-items-start form-item mb-3","element-class":"mr-2"},model:{value:(_vm.newsletter),callback:function ($$v) {_vm.newsletter=$$v},expression:"newsletter"}}),_vm._v(" "),_c('formulate-input',{attrs:{"type":"checkbox","label":"Ich akzeptiere die Datenschutzbestimmungen*","validation":"accepted","validation-messages":{
            accepted: '* Bitte akzeptieren Sie die Datenschutzbestimmungen',
          },"wrapper-class":"d-flex align-items-start form-item privacy-checkbox","element-class":"mr-2"},model:{value:(_vm.form.privacy),callback:function ($$v) {_vm.$set(_vm.form, "privacy", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.privacy"}}),_vm._v(" "),_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.hp),expression:"form.hp"}],staticClass:"out-of-display",attrs:{"type":"checkbox","placeholder":"check this field for security reasons","name":"checkbox","tabindex":"-1","autocomplete":"false"},domProps:{"checked":Array.isArray(_vm.form.hp)?_vm._i(_vm.form.hp,null)>-1:(_vm.form.hp)},on:{"change":function($event){var $$a=_vm.form.hp,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.form, "hp", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.form, "hp", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.form, "hp", $$c)}}}}),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.hp2),expression:"form.hp2"}],staticClass:"out-of-display",attrs:{"type":"text","placeholder":"fill this field with your firstname","name":"firstname","tabindex":"-1","autocomplete":"false"},domProps:{"value":(_vm.form.hp2)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "hp2", $event.target.value)}}}),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.hp3),expression:"form.hp3"}],staticClass:"out-of-display",attrs:{"type":"email","placeholder":"type your email here","name":"email","tabindex":"-1","autocomplete":"false"},domProps:{"value":(_vm.form.hp3)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "hp3", $event.target.value)}}})]),_vm._v(" "),_c('formulate-input',{attrs:{"type":"submit","input-class":"button mx-0 mb-0 w-100"}},[_vm._v("Senden")])],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }
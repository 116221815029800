//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ImageComponent from "./ImageComponent.vue";
import { ScrollTrigger } from "gsap/ScrollTrigger";
export default {
  name: "ImageSeparator",
  components: { ImageComponent },
  props: {
    block: {
      type: Object,
      required: true,
    },
  },
  methods: {
    breakBlockHeadlines() {
      this.$gsap.fromTo(
        `.image-separator__headline--left-${this.block.id}`,
        {
          x: "250%",
          opacity: 0,
        },
        {
          x: "50%",
          opacity: 1,
          scrollTrigger: {
            trigger: `.image-separator-${this.block.id}`,
            end: "bottom",
            scrub: true,
          },
        }
      );
      this.$gsap.fromTo(
        `.image-separator__headline--right-${this.block.id}`,
        {
          x: "-50%",
          opacity: 0,
        },
        {
          x: 0,
          opacity: 1,
          scrollTrigger: {
            trigger: `.image-separator-${this.block.id}`,
            end: "bottom",
            scrub: true,
          },
        }
      );
    },
  },
  mounted() {
    this.breakBlockHeadlines();
    ScrollTrigger.refresh();
  },
};

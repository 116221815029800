import { render, staticRenderFns } from "./default.vue?vue&type=template&id=00ec741b&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"
import style0 from "./default.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TheHeader: require('/app/components/TheHeader.vue').default,ScrollTopButton: require('/app/components/ScrollTopButton.vue').default,TheFooter: require('/app/components/TheFooter.vue').default})

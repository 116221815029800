//
//
//
//
//
//
//
//
//

export default {
  props: {
    speed: {
      type: Number,
      default: 50,
    },
  },
  data() {
    return {
      resizeObserver: null,
      animation: null,
    };
  },
  mounted() {
    this.initCarousel();
    this.resizeObserver = new ResizeObserver(() => {
      this.restartAnimation();
    });
    this.resizeObserver.observe(this.$refs.logosCarousel);
  },
  beforeDestroy() {
    if (this.resizeObserver) this.resizeObserver.disconnect();
    if (this.animation) this.animation.kill();
  },
  methods: {
    initCarousel() {
      this.$nextTick(() => {
        const container = this.$refs.logosCarouselContainer;
        if (!container) return;

        const width = container.scrollWidth / 2; // Half, since we duplicated the content
        this.animation = this.$gsap.to(container, {
          x: `-${width}px`,
          duration: this.speed,
          repeat: -1,
          ease: "linear",
        });
      });
    },
    restartAnimation() {
      if (this.animation) this.animation.kill();
      this.initCarousel();
    },
  },
};

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "SquareItem",
  data() {
    return { url: process.env.api_url };
  },
  props: {
    background_color: {
      type: String,
    },
    block: {
      type: Object,
    },
  },
  methods: {
    trianglesIn() {
      const triangleItems = [
        ...document.getElementsByClassName(
          `features__triangle-pattern__bottom`
        ),
      ];
      if (triangleItems.length) {
        triangleItems.map((triangleItem, i) => {
          this.$gsap.fromTo(
            triangleItem,
            {
              opacity: 0,
              x: -150,
            },
            {
              opacity: 1,
              x: 300,
              scrollTrigger: {
                trigger: triangleItem,
                start: "top 100%",
                // end: "bottom -50%",
                scrub: true,
              },
            }
          );
        });
      }
      return;
    },
  },
  mounted() {
    this.trianglesIn();
  },
};

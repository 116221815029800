//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VideoElementAutoplay from "./VideoElementAutoplay.vue";
export default {
  components: {
    VideoElementAutoplay,
  },
  props: {
    marqueeItems: {
      type: Array,
      default: () => [],
    },
    basicFeatureItems: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      url: process.env.api_url,
    };
  },
};
